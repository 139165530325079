import React from "react";

import Menu from "menu";
import Banner from "banner";

import { Container, Typography, Grid, Paper, Box , Button, LinearProgress, Avatar, Link } from '@mui/material';


import { useTranslation } from 'react-i18next';

const Soft = ({title, url, icon_, desc}) => {
	return (
		<Link href={url} underline="none">
			<Grid container spacing={2}>
				<Grid item xs={4} md={1}>
					<img src={icon_}  width="40px"/>
				</Grid>
				<Grid item xs={4} md={3}>
					<Typography variant="h6"><Box sx={{ fontWeight: 'bold' }}>{title}</Box></Typography>
				</Grid>
				<Grid item xs={4} md={8}>
					<Typography variant="h6">{desc}</Typography>
				</Grid>
			</Grid>
		</Link>
	)
}



export default (() => {

	const { t, i18n } = useTranslation("links");

	return <Box>
		<Menu idx={3} />
		<Banner title1={t('title')} image="/res/links/link.png"  />

		<Container>

			<Paper sx={{ m: 1 }}>
				<Typography variant="h5">{t('security')}</Typography></Paper>
				<Soft title="Keepass" url="https://keepass.info/" icon_="/res/links/keepass.png" desc={t('keepass')}/>
			

			<Paper sx={{ m: 1 }}>
				<Typography variant="h5">{t('utilities')}</Typography></Paper>
				<Soft title="Ditto Clipboard manager" url="https://ditto-cp.sourceforge.io/" icon_="/res/links/ditto.png" desc={t('ditto')}/>
				<Soft title="Agent Ransack" url="https://www.mythicsoft.com/agentransack/" icon_="/res/links/agentransack.png" desc={t('agentRansack')}/>
				<Soft title="Greenshot" url="https://getgreenshot.org/" icon_="/res/links/greenshot.png" desc={t('greenshot')}/>
				<Soft title="Wiztree" url="https://diskanalyzer.com/download" icon_="/res/links/wiztree.png" desc={t('wiztree')}/>
			

			<Paper sx={{ m: 1 }}>
				<Typography variant="h5">{t('image')}</Typography></Paper>
				<Soft title="The Gimp" url="https://www.gimp.org/" icon_="/res/links/gimp.png" desc={t('gimp')}/>
				<Soft title="Paint.net" url="https://www.getpaint.net/index.html" icon_="/res/links/paint.webp" desc={t('paint')}/>
			

			<Paper sx={{ m: 1 }}>
				<Typography variant="h5" >{t('video')}</Typography></Paper>
				<Soft title="Da Vinci Resolve" url="https://www.blackmagicdesign.com/fr/products/davinciresolve" icon_="/res/links/davinci.png" desc={t('davinciresolve')}/>
			

			<Paper sx={{ m: 1 }}>
				<Typography variant="h5">3D</Typography></Paper>
				<Soft title="Blender" url="https://www.blender.org" icon_="/res/links/blender.png" desc={t('blender')}/>
			

			<Paper sx={{ m: 1 }}>
				<Typography variant="h5">{t('dev')}</Typography></Paper>
				<Soft title="Notepad++" url="https://notepad-plus-plus.org/" icon_="/res/links/notepad.png" desc={t('notepad')}/>
				<Soft title="Qt" url="https://www.qt.io/" icon_="/res/links/qt.png" desc={t('qt')}/>	
				<Soft title="Visual Studio Code" url="https://code.visualstudio.com/" icon_="/res/links/visualstudiocode.png" desc={t('visualstudiocode')} />
				<Soft title="Fork" url="https://git-fork.com/" icon_="/res/links/fork.png" desc={t('fork')}/>
				<Soft title="Python" url="https://www.python.org/" icon_="/res/links/python.png" desc={t('python')}/>
				<Soft title="FileZilla" url="https://filezilla-project.org/" icon_="/res/links/filezilla.png" desc={t('filezilla')} />
				<Soft title="CMake" url="https://cmake.org/" icon_="/res/links/cmake.png" desc={t('cmake')} />
			


	


			
		</Container>
	</Box>
});