export default 
{
    title: "Mes logiciels",
    windowsTxt : "Mes logiciels développés pour Windows, avec Qt en C++",
    organization: "Organisation",
    download: "Télécharger",




    ti89: {
      desc: "Des jeux développés sur TI-89 en Basic",

      maillon: {
        title: "Le Maillon Faible",
        desc: "Adaptation du jeu télé sur calculatrice, jouable jusqu'à 9 joueurs!"
      },

      dcedl :{
        title: "Des Chiffres et des Lettres",
        desc: "Adaptation du jeu TV de France 3, jouable seul ou à 2 avec le câble"
      },
    }
};