export default 
{
    desc: "Planificateur de tâches avancé, qui permet de suivre l'avancement de tâches, mais aussi le stockage de liens Internet ou de fichiers, l'enregistrement et la génération de mots de passe, de programmer des rappels, de lancer des scripts d'automatisation....",
    title2 :"Planificateur de tâches et aide-mémoire",
    desc2: "Tasky est un outil d'organisation et de productivité qui permet de créer des listes de tâches de façon très simple et intuitive, stockez des liens Internet ou vers des fichiers, programmer des rappels, enregistrer des mots de passe...",

    text3 : `Ce logiciel peut ainsi répondre à de nombreux usages pour améliorer la productivité. Par exemple, vous pouvez l'utiliser pour:
    <ul>
      créer des listes de tâches organisées par priorité
      vous rappeler des rendez-vous importants
      avoir un outil de prises de notes/memo
      avoir un gestionnaire de liens Internet favoris
      avoir un gestionnaire/générateur de mots de passe protégé
      avoir une liste de contacts avec des rappels d'anniversaire!
      lancer des scripts simples pour effectuer des tâches répétitives rapidement
    </ul>`,

    taskTypes: 'Types de tâches',


    tasks: {
        name: "Tâches",
        desc: "Cases à cocher pour suivre l'avancement de tâches"
    },
    labels: {
        name: "Etiquettes",
        desc: "Mise en forme des listes"
    },
    links: {
        name: "Liens",
        desc: "Stockez des liens vers un site Internet, un email ou un fichier/dossier"
    },
    lists: {
        name: "Listes",
        desc: "Lien vers une autre liste"
    },
    contact: {
        name: "Contact",
        desc: "Enregistrez un email, un téléphone, une adresse, un anniversaire..."
    },
    scripts: {
        name : "Scripts",
        desc: "pour automatiser des tâches"
    },

    features:"Fonctionnalités complémentaires",

    alerts: {
        name: "Alertes",
        desc: "Programmez un rappel pour vos tâches"
    },
    passwords: {
        name: "Mots de passe",
        desc: "Stockez et générez vos mots de passe"
    },
    memo: {
        name: "Memo",
        desc: "Sauvegardez des notes plus détaillées"
    },
    crypt: {
        name: "Cryptage de listes",
        desc: "Protégez vos listes par mot de passe pour garantir la confidentialité des données"
    },
    format: {
        name: "Mise en forme",
        desc: "Ajoutez de la couleur et du style au texte"
    },
    print: {
        name: "Impression",
        desc: "Imprimez vos listes de tâches"
    },
    importBookmarks: {
        name: "Import favoris Internet",
        desc: "Importez vos favoris directement depuis votre navigateur Internet"
    },
    importKeepass: {
        name: "Import Keepass",
        desc: "Importez vos mots de passe depuis Keepass"
    },

};