export default 
{
  title: "Layer by Layer Method",
  level:"Beginner level",
  desc: "A simple solving method with few algorithms to learn",
  p1: `Here’s a relatively easy method to understand and learn how to solve the Rubik's Cube. 
    In fact, this method only requires learning about ten simple algorithms that are easy to remember, summarized in the Quick Card. 
    We will approach it methodically. 
    For those who think they will never be able to do it, this method is made for you! 
    In about a hundred moves of the faces, the cube will be like new again, ready to be scrambled once more!`,

  p2 : `On the other hand, don't expect to break records with this method. 
      While it's simple, it’s still quite lengthy! 
      Don’t hope to easily go below a minute for solving the cube. 
      For more impressive results, and with a lot of perseverance, you can then tackle the Fridrich method. 
      The idea behind this solving method (like most, actually...) is to solve the cube layer by layer, as the name suggests. 
      The cube has 3 layers, so you start by solving one layer (actually one face), then the middle layer, and finally the last face.`,

  p3 : `This method is divided into 8 steps:`, 

  preRequis :{
      p1 : `Know that the color located on the center piece of a face corresponds to THE color of that face (indeed, since this piece cannot be moved!).
      For solving the first face, the one on top, I recommend choosing a color and always starting with that same color:
      (Here, it will be the blue face). 
      You will see that we quickly get used to a color and solve it much faster this way.`,
  },



  layer1_title: "1st Layer",

  layer11_title: "Cross on first face",

cross1 : `The first step is to build a cross oriented on the upper face of the cube. 
  Oriented means that the 4 edges that make up the cross have their 2 colors matching the centers of the adjacent faces... 
  The goal is to achieve the same configuration as in the image above (with your own colors). 
  Here, you can see that the blue cross is made on the blue center face, and the colors of the 4 edges match the centers of the neighboring faces
  Use the following algorithms to solve each of the 4 edges that make up the cross:"`,
face1 : `	Une fois les arêtes de la première face placées, profitons-en pour terminer la première face en plaçant les 4 derniers coins (on se contentera d'en résoudre 3 en fait pour faciliter l'étape suivante)
    Commencez par repérer un coin de cette face : une des trois couleurs du coin doit être la même que celle de la face à résoudre, les deux autres couleurs vous indiquant l'emplacement du coin.
    Une fois un coin trouvé et sa destination, de la même facon, trouvez le moyen par vous-même pour remonter le coin, orienté, sur la tranche supèrieure, sans casser la croix reconstituée lors de la première étape.
    `,

    layer12_title: "Placement of the corners of the first face",
    couronne: `	Attaquons-nous maintenant à la seconde couche : la couronne centrale.
    Pour cela, on se sert de l'emplacement du dernier coin non résolu de la première face.
    Commencez par repérer un bord de la couronne centrale sur la face inférieure ainsi que son emplacement désiré sur la couronne centrale (les deux couleurs du bord doivent coincider avec les couleurs des centres des faces).
    Tournez ensuite la face du haut (avec des mouvements U) pour amener le coin non résolu de la première face au-dessus de l'emplacement du bord de l'arête centrale.
    Vous vous retrouverez alors dans une des 2 situations suivantes. Utilisez l'algorithme approprié pour résoudre alors le bord.`,




    layer2_title: "2nd Layer",

    layer21_title: "Central layer",

    layer2: `Appliquer cette même méthode pour résoudre les 4 bords et terminer ainsi la couronne centrale.
  Attention ! Si jamais un des bords ne se trouve pas sur la face inférieure mais sur la couronne centrale, mal positionné ou mal orienté, l'utilisation d'un des 2 algorithmes précédents permet de descendre le bord sur la face D.`,



  layer22_title: "Resolution of the last corner of the first face",
    layer2_2 : `Maintenant, on va résoudre le dernier coin de la première face sans casser le reste, l'occasion de découvrir enfin le premier "vrai" algorithme.
  Pour cela, amenez le coin restant dans une des deux configurations suivantes avec des mouvements D. (Si le coin est dans une autre position/orientation, il faut arriver à le déloger en utilisant un des deux algorithmes)`,
  
layer3_title: "3rd Layer",
layer31_title: "Last face cross",
  layer3_1: `Les deux tiers du cube sont maintenant finis. Tournez le cube de 180° pour avoir la face terminée tournée vers le bas.
    Le plus dur va maintenant être de finir la dernière face (celle du dessus maintenant) sans casser ce qui vient d'être fait.
    On commence par faire une croix non orientée sur la face du haut.
    Appliquez alors l'algorithme dédié (si aucun bord n'est en place, faites un des deux algorithmes précédent pour vous retrouver dans une des deux configurations I ou L) :`,

    layer32_title: "Cross orientation",
  layer3_2 : `Maintenant on va orienter la croix : faire coincider les couleurs des bords avec les centres des faces voisines.
    En tournant la face du haut avec des mouvements U, on se retrouve dans l'une des configurations suivantes dans laquelle un bord uniquement est bien positionné et les 3 autres doivent être permutés (sinon, appliquez n'importe lequel des 2 algorithmes pour se retrouver dans une de ces 2 situations) :`,

    layer33_title: "Placement of last corners",
    layer3_3: `	On touche au but : plus que 4 coins à résoudre.
    Pour commencer, comprenez bien la différence entre un coin orienté et un coin placé : un coin orienté est un coin résolu, alors qu'un coin placé signifie que les trois couleurs qui le composent correspondent aux couleurs des trois faces voisines sans forcément être bien orienté. 
    Un coin placé peut ainsi avoir 3 orientations différentes, une seule étant correctement résolue...`,

    layer34_title: "Orientation of last corners",
    layer3_4 : `Passez en revue les 4 coins et comptez le nombre de coins bien placés (orientés ou non). 
    Dans l'idéal, vous en aurez 4 et vous pourrez passer à l'étape suivante.
    Si vous en avez un seul, appliquez l'algorithme qui vous permettra de placer (sans forcément orienter) tous les coins à la bonne place :`,

    layer3_5 : `
    Il s'agit maintenant de résoudre les 4 derniers coins de la face du haut (les tourner finalement). Avec un peu de chance, vous pouvez en avoir de déjà bien orientés.

    On va devoir résoudre chacun des 4 coins un par un.
    Le coin à résoudre sera positioné en UFR (au croisement des faces U, F et R), le coin face à nous sur les images.
    3 cas peuvent apparaitre, appliquez le bon algorithme parmi:`,

    layer3_6: `	Si tout s'est bien passé, le coin doit être résolu (et oui, le reste du cube est cassé, mais c'est normal!)
    Peu importe, tous doivent être résolus de la même  facon, l'un après l'autre. 
    On peut passer au suivant : avec un mouvement U on amène le coin suivant à l'emplacement de résolution UFR et on recommence de la même façon la résolution du coin face à nous`,

    layer3_exemple: `Voici un exemple avec 4 coins à résoudre, chacun des algorithmes est appliqué 2 fois selon le cas, le coin de départ devant être tourné dans le sens horaire, le suivant anti-horaire, le 3ème sens horaire, et le dernier anti-horaire. 
    Repérerez bien les mouvements U, ils ne servent qu'à amener le coin suivant en position de résolution, la position du coin face à nous:`,
};
  