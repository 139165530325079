export default 
{
  title: "Welcome on my website",
  desc: `You can find on this website:
  <ul>my own software, developped for Windows<li>Rubik's Cube solving methods`,
  software: "My software",
  rubik: "How to solve a Rubik's Cube?",


  menu : {
    home : "Home",
    software: "My software",
    rubik: "Rubik's Cube",
    links: "Links",
    about: "About..."
  },

  form: {
    text: `Any improvement idea ?
    Any bug ?
    Any encouragement ?`,
    send: 'Send',
    placeholder: 'Your message'
  }
};
  