export default 
{
    title: "Méthode Fridrich",
    level:"Méthode expert",
    desc: "Une des méthodes les plus rapides utilisée par la plupart des meilleurs speedcubers pour battre les records!",

    header: `   Du nom de son inventrice, Jessica Fridrich, c'est LA méthode ultime, LA méthode la plus utilisée dans les compétitions de speedcubing (résolution la plus rapide possible). 
                Une fois parfaitement maitrisée, elle permet de descendre sous la barre des 10 secondes pour les meilleurs... !
                Je ne vous cache pas qu'elle est un peu plus compliquée é apprendre que la méthode "Couche par couche" car elle demande l'apprentissage de pas moins de 78 algorithmes... c'est le prix à payer pour une résolution qui tourne aux alentours de 60 mouvements.
                Avant de vous précipiter sur cette méthode, je vous conseille de commencer par la méthode "Couche par couche", beaucoup plus simple à apprendre que celle-ci.`,

    header2: 'Cette méthode se décompose en 4 étapes :',

    title1: 'Croix de la première face',
    title2: "F2L: First 2 Layers",
    title3: "OLL: Orientation Last Layer",
    title4: "PLL: Permutation Last Layer",


    step1: ` Cette étape est exactement la méme que pour la méthode "Couche par couche" : elle consiste a construire une croix orientée sur la premiére face de son choix.
      Débrouillez-vous... mais sachez que la résolution de la croix peut presque toujours se faire en 7 mouvements maximum !`,
    faster: 'Pour aller plus vite:',

    step1b: ` <ul>
     Faites la croix sur la face du bas : cela évite d'avoir à retourner le cube pour l'étape suivante et permet ainsi de gagner ainsi de précieuses fractions de secondes !
     Apprenez à faire la croix en orientant les arétes de façon relative : il est inutile de recentrer la face à chaque aréte résolue. En connaissant la position relative de chaque couleur par rapport aux autres, il est ainsi possible de reconstituer la croix et de ne la rencentrer qu'une seule fois avec des mouvemnets U.
     </ul>`,

     step2: `On va ici regrouper deux étapes de la méthode "Couche par couche" en une seule : on termine la résolution de la première face tout en complétant en même temps la couronne centrale.
On commence par retourner la cube, de façon à avoir la croix de la première face sur la face du bas. On va ainsi résoudre les deux premières couches à l'envers. Cela permet d'avoir une meilleure visibilité sur la face du haut, celle par laquelle on finira le cube.
On s'intéresse ici à résoudre les 4 slots : un slot est l'association d'un coin et de l'arète associée sur la couronne centrale.
Pour résoudre les 4 slots et compléter ainsi cette étape, la méthode consiste à regrouper les pièces du slot correspondant sur la face du haut avant de le faire "glisser" dans son emplacement définitif sur les 2 couches du bas.`,

    step2b: `<ul>
     Un terme barbare : le Multislotting. Cette optimisation consiste, pendant la résolution d'un slot, à anticiper sur la résolution du slot suivant de façon à en faciliter l'insertion.
     </ul>`,

     step3: `Cette étape consiste à résoudre les couleurs de la dernière face, sans se préoccuper de la couleur des côtés de la troisième couche (celle du haut)
En fonction de l'étape précédente, on peut se retrouver dans une des 57 configurations possibles, soit autant d'algorithmes à apprendre...`,

    step3b: `Ici, pas de secret, une fois l'algorithme à utiliser repéré, il faut s'entrainer à le faire le plus rapidement possible.
C'est ici que les "Finger Tricks" interviennent : ce sont des successions de mouvements trés rapides à exécuter avec les doigts. 
Apprenez à enchaîner les mouvements sans temps mort, à commencer le mouvement suivant avant la fin du précédent, à utiliser les doigts des deux mains pour enchaîner plus rapidement les algorithmes.
Regardez donc des vidéos de speedcubing sur Internet, vous comprendrez par vous-méme... A condition aussi d'avoir un cube adapté !`,

    linkAlgos: 'Cliquez ici pour la liste des algorithmes',

    step4: `Dans cette dernière étape, on va permuter les pièces de la dernière face pour terminer le cube une bonne fois pour toutes !
Il existe 21 algorithmes différents. Une fois la configuration du cube identifiée, cet algorithme permet de résoudre définitement le cube.`,


};