export default 
{
    desc: "Un logiciel pour se chronométrer lors de la résolution du Rubik's Cube notamment",
    title2 :"Chronomètre pour Rubik's Cube",
    desc2: "Virtual Stackmat permet de chronométrer son temps.\nLe Stackmat timer est utilisé en compétition de Rubik's Cube notamment.",
    desc3: `Précisez votre pseudo avant de lancer le chrono,
Pour démarrer le chronomètre:
<ul>
appuyez sur le bouton Alt (à gauche de la barre Espace) et Ctrl (à droite de la barre Espace): le voyant rouge s'allume.
Laissez appuyer 2 secondes, jusqu'à ce que le voyant verts'allume à son tour: le chonomètre est ainsi amorcé.
Maintenant, le chronomètre se déclenchera dès que l'une des 2 touches sera relâchée.
</ul>
Pour arrêter le chronomètre, appuyez sur la barre Espace
Le logiciel classe ensuite automatiquement les temps et calcule la moyenne de chaque joueur`,
};