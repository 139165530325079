export default 
{
    desc: "Advanced task planner, which allows tracking the progress of tasks, as well as storing internet links or files, saving and generating passwords, scheduling reminders, and running automation scripts...",
    title2 :"Task manager and reminder",
    desc2: "Tasky is an organization and productivity tool that allows you to create task lists in a very simple and intuitive way, store internet links or file links, set reminders, save passwords...",

    text3 : `This software can thus serve many purposes to improve productivity. For example, you can use it to:
    <ul> 
      create organized task lists by priority
      remind you of important appointments
      have a note-taking/memo tool
      manage your favorite internet linkshave a protected password manager/generator
      keep a contact list with birthday reminders!
      run simple scripts to perform repetitive tasks quickly
    </ul>`,

    taskTypes: 'Tasks Types',
    
    tasks: {
        name: "Tasks",
        desc: "Checkboxes to track task progress"
    },
    labels: {
        name: "Labels",
        desc: "Formatting of lists"
    },
    links: {
        name: "Links",
        desc: "Store links to a website, an email, or a file/folder"
    },
    lists: {
        name: "Lists",
        desc: "Link to another list"
    },
    contact: {
        name: "Contact",
        desc: "Save an email, phone number, address, birthday..."
    },
    scripts: {
        name : "Scripts",
        desc: "to automate tasks"
    },

    features:"Additional features",

    alerts: {
        name: "Alerts",
        desc: "Schedule a reminder for your tasks"
    },
    passwords: {
        name: "Passwords",
        desc: "Store and generate your passwords"
    },
    memo: {
        name: "Memo",
        desc: "Save more detailed notes"
    },
    crypt: {
        name: "Encryption of lists",
        desc: "Protect your lists with a password to ensure data confidentiality"
    },
    format: {
        name: "Formatting",
        desc: "Add color and style to the text"
    },
    print: {
        name: "Print",
        desc: "Print your task lists"
    },
    importBookmarks: {
        name: "Import favoris Internet",
        desc: "Import your bookmarks directly from your web browser"
    },
    importKeepass: {
        name: "Import Keepass",
        desc: "Import your passwords from Keepass"
    },
};
  