export default 
{
  title: "Fridrich Method",
  level:"Advanced level",
  desc: "One of the fastest methods used by most of the top speedcubers to break records!",

  header: `   Named after its inventor, Jessica Fridrich, this is THE ultimate method, THE most used method in speedcubing competitions (the fastest possible solution).
Once fully mastered, it allows top solvers to get under the 10-second mark...!
I won’t hide the fact that it’s a bit more complicated to learn than the "Layer by Layer" method, as it requires learning no less than 78 algorithms... that’s the price to pay for a solution that takes around 60 moves.
Before rushing into this method, I recommend starting with the "Layer by Layer" method, which is much easier to learn than this one.`,

header2: 'This method is broken down into 4 steps:',

title1: 'Cross on First face',
title2: "F2L: First 2 Layers",
title3: "OLL: Orientation Last Layer",
title4: "PLL: Permutation Last Layer",

step1: ` This step is exactly the same as for the "Layer by Layer" method: it consists of creating a cross oriented on the first face of your choice.  
Figure it out... but know that the cross can almost always be solved in a maximum of 7 moves!`,

faster: 'To go faster:',

step1b: ` <ul>
    Make the cross on the bottom face: this avoids having to turn the cube for the next step and helps save precious fractions of a second!
    Learn to make the cross by orienting the edges relative to each other: there’s no need to re-center the face after each edge is solved. By knowing the relative position of each color in relation to the others, you can reconstruct the cross and only re-center it once with U moves.
</ul>`,

step2: `Here, we’ll combine two steps from the "Layer by Layer" method into one: we finish solving the first face while simultaneously completing the middle layer's edges.
Start by flipping the cube so that the cross of the first face is now on the bottom. This way, we’ll solve the first two layers in reverse order. This gives better visibility of the top face, which is the face we’ll finish the cube with.
The focus here is on solving the 4 slots: a slot is the combination of a corner piece and its associated edge in the middle layer.
To solve the 4 slots and complete this step, the method involves grouping the corresponding slot pieces on the top face before "sliding" them into their final position on the bottom two layers.`,

step2b: `<ul> 
A technical term: Multislotting. This optimization involves, while solving one slot, anticipating the resolution of the next slot in order to make its insertion easier. 
</ul>`,

step3: `This step involves solving the colors of the last face, without worrying about the colors of the sides of the third layer (the top layer).  
Depending on the previous step, you may end up in one of 57 possible configurations, meaning that's how many algorithms there are to learn...`,

step3b: `Here, there’s no secret: once you’ve identified the algorithm to use, you need to practice executing it as quickly as possible.
This is where "Finger Tricks" come into play: these are sequences of very fast moves performed with the fingers.
 Learn to chain moves without any pauses, start the next move before the previous one is finished, and use both hands to execute algorithms faster.
So, watch speedcubing videos online—you’ll understand it yourself... but you also need to have a properly set-up cube!`,

linkAlgos: 'Click here for the list of algorithms',

step4: `In this final step, we’ll permute the pieces of the last face to complete the cube once and for all!
There are 21 different algorithms. Once the cube’s configuration is identified, this algorithm will solve the cube permanently.`,

};
  