export default 
{
    desc: "A software that helps find the steps to solve a scrambled Rubik's Cube, while also offering various learning methods for solving it",
    title2:"Solve the Rubik's Cube and learn how to do",
    desc2: "Rubik Teacher is a program that not only helps solve a scrambled Rubik's Cube through its edit mode, but also, and most importantly, teaches how to solve it using two different methods through the learning mode",
    modeSolve: "Solve mode",
    modeSolveDesc: "Draw the configuration of a scrambled Rubik's Cube, and the program will find the solution to solve it step by step",
    modeLearn: "Learn mode",
    modeLearnDesc: "Learn the methods for solving the Rubik's Cube through 2 techniques based on skill level",
    modeLearnDesc2: `<ul>
Layer-by-Layer Method: for beginners, it allows solving the cube in about a hundred moves.
Fridrich Method: expert method, which allows solving the cube in less than 60 moves and is widely used in competitions to go under the 10-second mark!
                    </ul>`,

    title1: "Software Introduction",

    title11: `Initial cube`,
    area11: `This area is used to color the cube. The palette allows you to "paint" the cube on the exploded view, while the different buttons serve the following purposes:

<ul>
Fill the cube in "solved" mode
Completely empty the cube
Check the validity of the cube
</ul>`,

    title12: `Random scramble`,
    area12: `Choose the number of random moves before scrambling the cube.`,

    title13: `Moves`,
    area13: `Click on one of the 36 possible moves to animate the 3D cube, scramble it, and possibly solve it!`,


    title14: `3D cube display area`,
    area14: `The different buttons serve the following purposes:
<ul>
Recenter the cube to its original position
Animate the cube on/off
Clear the text area
</ul>`,

title15: `Solution display area and the various solving algorithms`,

title16: `Target Cube`,
area16: `Describes the target configuration at the different stages of solving.`,

title17: `Solving`,
area17: `The buttons serve the following purposes:
<ul>
Compare the different available solutions to see how many moves are needed with each method
Solve the cube!
</ul>`,

title18: `Algorithm viewer and 3D cube speed control`,


    p2 : `If you have a scrambled Rubik's Cube and want to find the solution to solve it, this program can solve it for you: 
    <ul> 
    Edit the cube in exploded view (1.d) to match the one on your screen. 
    Check the validity of the cube (1.c) Choose the solving method (Friedrich for the fastest) and display the solution (7.b) 
    This will display the moves to replicate at each step of the solution, which you can re/play at an adjustable speed using the algorithm player (8). 
    </ul>`,

    p3: `If you want to learn how to solve the Rubik's Cube, know that the program displays the solution along with the different steps of the solution.
It also allows you to navigate through the different solving algorithms:
If you scramble the cube (3) and display the solution (7.a/b), you will see the algorithms used to solve each step of the solution appear (5).
By clicking on a step of the solution (5), the 3D view of the cube (4) will update to the initial state of that step. The view (1) will show the corresponding cube in exploded view. The view (6) displays the target state reached at the end of the step (with gray squares considered neutral at that step of the solution).
To learn all the algorithms needed to solve each step of each of the two methods, you can navigate through them by selecting the algorithm in section (7).`,

};
  