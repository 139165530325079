import React from "react";

import Menu from "menu";
import Banner from "banner";


import { Container, Typography, Grid, Paper, Box , Button, LinearProgress, Avatar, Link } from '@mui/material';

import { useTranslation } from 'react-i18next';

import { Paragraph } from "toolbox";
  

export default (() => {

	const { t, i18n } = useTranslation("rubik");

	const tLayer =  useTranslation("methodLayer");
	const tFridrich =  useTranslation("methodFridrich");

	
	const pairs = [
		["1974", t('invention')],
		["43,25 quintillions", t('combinations')],
		["20", t('moves')],
		["3.13 sec", t('wr')],
	  ];

	return <Box>
		<Menu idx={2} />
		<Banner title1="Rubik's Cube" title2={t('methods')} image="/res/rubik/rubik.png" page="rubik"/>

		<Container>
			<Box display="flex"> 
				<Paragraph  text='p001' t={t}/>
				
				<Button variant="contained" href="/rubikTeacher">Rubik Teacher</Button>
			</Box>

			<Typography variant="h2_software" sx={{m:3}}>{t('numbers')}</Typography>


			<Grid container spacing={2} justifyContent="center" direction="row">
				{pairs.map(([k,v]) => (
					<Grid item xs={3} key={k}>
					<Paper elevation={3}  
						style={{ 
							height: '150px', // Hauteur fixe
							display: 'flex', 
							alignItems: 'center', 
							justifyContent: 'center',
							textAlign: 'center' ,
							flexDirection: 'column',
						}}
					>
						<Typography variant="h4">{k}</Typography>
						<Typography>{v}</Typography>
					</Paper>
					</Grid>
				))}
			</Grid>



			<Typography variant="h2_software" sx={{m:3}}>{t('title2')}</Typography>



			<Paragraph text='desc' t={t} />



			<Link href="/methodLayer" underline="none" >
				<Paper sx={{mt:6, backgroundColor: '#abebc6', padding: 2 }}>
					<Grid container spacing={2}>
						<Grid item xs={4} md={3}>
							<img src="/res/rubik/layer/rubik_baby2.png" className="responsive-image" style={{  borderRadius:'20px' }}/>				
						</Grid>
						<Grid item xs={4} md={9}>
							<Typography variant="h1_software">{tLayer.t('level')}</Typography>
							<Typography variant="h2_software">{tLayer.t('title')}</Typography>
							<Paragraph  text='desc' t={tLayer.t} />
						</Grid>
					</Grid>
				</Paper>
			</Link>


			<Link href="/methodFridrich" underline="none">
				<Paper sx= {{ mt:3, mb:5 , backgroundColor: "#d7bde2", padding: 2 }}>
					<Grid container spacing={2}>
						<Grid item xs={4} md={9}>
							<Typography variant="h1_software">{tFridrich.t('level')}</Typography>
							<Typography variant="h2_software">{tFridrich.t('title')}</Typography>
							<Paragraph  text='desc' t={tFridrich.t} />
						</Grid>
						<Grid item xs={4} md={3}>
							<img src="/res/rubik/fridrich/rubik_robot.png" className="responsive-image" style={{  borderRadius:'20px' }}/>				
						</Grid>
					</Grid>
				</Paper>
			</Link>
		</Container>
	</Box>
});