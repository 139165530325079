export default 
{
  title: "Méthode Couche par Couche",
  level:"Méthode débutant",
  desc: "Une méthode de résolution facile avec peu d'algorithmes à apprendre",
  p1: `Voici une méthode relativement facile à comprendre mais aussi à apprendre pour résoudre le Rubik's Cube.
        En effet, cette méthode ne demande l'apprentissage que d'à peine une dizaine d'algorithmes assez simples à retenir, récapitulés dans la Quick Card ci-contre.
        On va s'y prendre méthodiquement.
        Pour ceux qui pensent ne jamais y arriver, cette méthode est faite pour vous !
        Dans une centaine de mouvements des faces, le cube sera comme au premier jour, prêt à être mélangé à nouveau !`,
  p2 : `Par contre, ne vous attendez pas à pulvériser les records avec cette méthode. Simple, elle n'en reste pas moins longue ! 
        N'espérez que difficilement descendre en-dessous d'une minute pour la résolution du cube.
        Pour des résultats plus convaincants, et avec beaucoup de persévérance, vous pourrez ensuite vous frotter à la méthode Fridrich.
        L'idée de cette méthode de résolution (comme la plupart d'ailleurs...) consiste à résoudre le cube couche par couche, comme son nom l'indique.
      Le cube a 3 couches, donc on résout d'abord une couche (une face en fait), puis la couronne centrale, pour finir avec la dernière face.`,
  p3 : `Cette méthode se décompose en 8 étapes :`, 

  preRequis :{
    p1 : `Sachez que la couleur située sur la pièce centrale d'une face correspond à LA couleur de cette face (en effet, puisque cette pièce ne peut être déplacée !)<br />			
    Pour la résolution de la première face, celle du dessus, je vous conseille de choisir une couleur et de toujours commencer par cette même couleur :<br />
    (Ici, ce sera la face bleue)
    Vous verrez qu'on s'habitue très vite à une couleur et qu'on le résout ainsi bien plus vite.`,
  },

  layer1_title: "1ère couche",

  layer11_title: "Croix de la première face",


  cross1 : `La première étape consiste à construire une croix orientée sur la face supérieure du cube. 
  Orientée signifie que les 4 bords qui constituent la croix ont leurs 2 couleurs coïncidant avec les centres des faces adjacentes...
  Le but est donc d'obtenir la même configuration que sur l'image ci-dessus (avec vos couleurs à vous).
  Ici, vous constatez que la croix bleue est faite sur la face de centre bleue et les couleurs des 4 arêtes correspondent bien avec les centres des faces voisines.


  Aidez-vous des algorithmes suivants pour résoudre chacun des 4 bords qui forment la croix:`,

  layer12_title: "Placement des coins de la 1ère face",
  face1 : `	Une fois les arêtes de la première face placées, profitons-en pour terminer la première face en plaçant les 4 derniers coins (on se contentera d'en résoudre 3 en fait pour faciliter l'étape suivante)
  Commencez par repérer un coin de cette face : une des trois couleurs du coin doit être la même que celle de la face à résoudre, les deux autres couleurs vous indiquant l'emplacement du coin.
  Une fois un coin trouvé et sa destination, de la même facon, trouvez le moyen par vous-même pour remonter le coin, orienté, sur la tranche supèrieure, sans casser la croix reconstituée lors de la première étape.
  `,
  couronne: `	Attaquons-nous maintenant à la seconde couche : la couronne centrale.
  Pour cela, on se sert de l'emplacement du dernier coin non résolu de la première face.
  Commencez par repérer un bord de la couronne centrale sur la face inférieure ainsi que son emplacement désiré sur la couronne centrale (les deux couleurs du bord doivent coincider avec les couleurs des centres des faces).
  Tournez ensuite la face du haut (avec des mouvements U) pour amener le coin non résolu de la première face au-dessus de l'emplacement du bord de l'arête centrale.
  Vous vous retrouverez alors dans une des 2 situations suivantes. Utilisez l'algorithme approprié pour résoudre alors le bord.`,

  layer2_title: "2ème couche",

  layer21_title: "Couronne centrale",
  layer2: `Appliquer cette même méthode pour résoudre les 4 bords et terminer ainsi la couronne centrale.
  Attention ! Si jamais un des bords ne se trouve pas sur la face inférieure mais sur la couronne centrale, mal positionné ou mal orienté, l'utilisation d'un des 2 algorithmes précédents permet de descendre le bord sur la face D.`,


  layer22_title: "Résolution du dernier coin de la première face",
  layer2_2 : `Maintenant, on va résoudre le dernier coin de la première face sans casser le reste, l'occasion de découvrir enfin le premier "vrai" algorithme.
  Pour cela, amenez le coin restant dans une des deux configurations suivantes avec des mouvements D. (Si le coin est dans une autre position/orientation, il faut arriver à le déloger en utilisant un des deux algorithmes)`,


  layer3_title: "3ème couche",

  layer31_title: "Croix de la dernière face",
  layer3_1: `Les deux tiers du cube sont maintenant finis. Tournez le cube de 180° pour avoir la face terminée tournée vers le bas.
  Le plus dur va maintenant être de finir la dernière face (celle du dessus maintenant) sans casser ce qui vient d'être fait.
  On commence par faire une croix non orientée sur la face du haut.
  Appliquez alors l'algorithme dédié (si aucun bord n'est en place, faites un des deux algorithmes précédent pour vous retrouver dans une des deux configurations I ou L) :`,

  layer32_title: "Orientation de la Croix",
  layer3_2 : `Maintenant on va orienter la croix : faire coincider les couleurs des bords avec les centres des faces voisines.
  En tournant la face du haut avec des mouvements U, on se retrouve dans l'une des configurations suivantes dans laquelle un bord uniquement est bien positionné et les 3 autres doivent être permutés (sinon, appliquez n'importe lequel des 2 algorithmes pour se retrouver dans une de ces 2 situations) :`,

  layer33_title: "Placement des derniers coins",
  layer3_3: `	On touche au but : plus que 4 coins à résoudre.
  Pour commencer, comprenez bien la différence entre un coin orienté et un coin placé : un coin orienté est un coin résolu, alors qu'un coin placé signifie que les trois couleurs qui le composent correspondent aux couleurs des trois faces voisines sans forcément être bien orienté. 
  Un coin placé peut ainsi avoir 3 orientations différentes, une seule étant correctement résolue...`,

  layer34_title: "Orientation des 4 derniers coins",
  layer3_4 : `Passez en revue les 4 coins et comptez le nombre de coins bien placés (orientés ou non). 
  Dans l'idéal, vous en aurez 4 et vous pourrez passer à l'étape suivante.
  Si vous en avez un seul, appliquez l'algorithme qui vous permettra de placer (sans forcément orienter) tous les coins à la bonne place :`,

  layer3_5 : `
  Il s'agit maintenant de résoudre les 4 derniers coins de la face du haut (les tourner finalement). Avec un peu de chance, vous pouvez en avoir de déjà bien orientés.

  On va devoir résoudre chacun des 4 coins un par un.
  Le coin à résoudre sera positionné en ULB (au croisement des faces U, L et B), le coin le plus éloigné de nous sur les images.
  3 cas peuvent apparaitre, appliquez le bon algorithme parmi:`,

  layer3_6: `	Si tout s'est bien passé, le coin doit être résolu (et oui, le reste du cube est cassé, mais c'est normal!)
  Peu importe, tous doivent être résolus de la même  facon, l'un après l'autre. 
  On peut passer au suivant : avec un mouvement de la face L on amène le coin suivant à l'emplacement de résolution ULB et on recommence de la même façon la résolution du coin face à nous`,

  layer3_exemple: `Voici un exemple avec 4 coins à résoudre, chacun des algorithmes est appliqué 2 fois selon le cas, le coin de départ devant être tourné dans le sens anti-horaire, le suivant horaire, le 3ème sens anti-horaire, et le dernier horaire. 
  Repérerez bien les mouvements L', ils ne servent qu'à amener le coin suivant en position de résolution, la position du coin le plus éloigné:`
};