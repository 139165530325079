// src/i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';


import translation_home_fr from './translation_fr';
import translation_home_en from './translation_en';

import translation_software_fr from './Software/translation_fr';
import translation_software_en from './Software/translation_en';
import translation_tasky_fr from './Software/Tasky/translation_fr';
import translation_tasky_en from './Software/Tasky/translation_en';
import translation_rubikTeacher_fr from './Software/RubikTeacher/translation_fr';
import translation_rubikTeacher_en from './Software/RubikTeacher/translation_en';
import translation_stackmatTimer_fr from './Software/VirtualStackmatTimer/translation_fr';
import translation_stackmatTimer_en from './Software/VirtualStackmatTimer/translation_en';

import translation_rubik_fr from './Rubik/translation_fr';
import translation_rubik_en from './Rubik/translation_en';
import translation_layer_fr from './Rubik/MethodLayer/translation_fr';
import translation_layer_en from './Rubik/MethodLayer/translation_en';
import translation_fridrich_fr from './Rubik/MethodFridrich/translation_fr';
import translation_fridrich_en from './Rubik/MethodFridrich/translation_en';

import translation_links_fr from './Links/translation_fr';
import translation_links_en from './Links/translation_en';

import translation_about_fr from './About/translation_fr';
import translation_about_en from './About/translation_en';
import translation_CV_fr from './About/translation_CV_fr';
import translation_CV_en from './About/translation_CV_en';


const resources = {
  en: {
    home: translation_home_en,
    software: translation_software_en,
    tasky: translation_tasky_en,
    rubikTeacher: translation_rubikTeacher_en,
    stackmatTimer : translation_stackmatTimer_en,
    rubik: translation_rubik_en,
    methodLayer: translation_layer_en,
    methodFridrich: translation_fridrich_en,
    links: translation_links_en,
    about: translation_about_en,
    CV: translation_CV_en,
  },
  fr: {
    home: translation_home_fr,
    software: translation_software_fr,
    tasky: translation_tasky_fr,
    rubikTeacher: translation_rubikTeacher_fr,
    stackmatTimer : translation_stackmatTimer_fr,
    rubik: translation_rubik_fr,
    methodLayer: translation_layer_fr,
    methodFridrich: translation_fridrich_fr,
    links: translation_links_fr,
    about: translation_about_fr,
    CV : translation_CV_fr
  },
};


i18n.use(initReactI18next).init({
  resources,
  lng: 'fr', // Langue par défaut
  fallbackLng: 'en', // Langue de secours si la langue sélectionnée n'est pas disponible
  interpolation: {
    escapeValue: false // React se charge de l'échappement XSS
  }
});

export default i18n;
