
import {  Paper, Box , Button, LinearProgress, Avatar, Link , Typography, TextField} from '@mui/material';



import { React, useState } from 'react';

import { useTranslation  } from 'react-i18next';


import { Paragraph } from 'toolbox';


function Form ({title}) 
{
	const { t, i18n } = useTranslation("home");


	const [text, setText] = useState('');
  
	const handleSubmit = (e) => {
	  e.preventDefault();
	  const email = 'sebastien.labonne@gmail.com'; // Remplace par l'adresse email de destination
	  const subject = title; // Sujet de l'email
	  const body = encodeURIComponent(text); // Encode le texte pour l'URL
  
	  // Ouvre le client mail
	  window.location.href = `mailto:${email}?subject=${subject}&body=${body}`;
	  setText(''); // Réinitialiser le champ texte
	};
  


	return <Box
				sx={{ 
					//backgroundColor: '#d6eaf8', // ou une couleur spécifique comme '#ff0000'
					padding: 2, // pour ajouter du padding
					borderRadius: '16px',
					mt: 5
				}}
			>


		<Box
			component="form"
			onSubmit={handleSubmit}
			sx={{
				display: 'flex',
				flexDirection: 'column',
				gap: 2,
				maxWidth: 600,
				margin: 'auto',
				padding: 2,
				border: '1px solid #ccc',
				borderRadius: '8px',
				textAlign: 'center' ,
			}}
		>
			<Paragraph text='form.text' t={t} />


			<TextField
				label={t('form.placeholder')}
				variant="outlined"
				value={text}
				onChange={(e) => setText(e.target.value)}
				required
				multiline
				rows={4}
			/>
			<Button variant="contained" type="submit">
				{t('form.send')}
			</Button>
		</Box>
	</Box>
};

export default Form;