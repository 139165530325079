import {React, useMemo} from "react";

import { Container, Typography, Box, Link, Grid, Button } from '@mui/material';

import Banner from "banner";



function BannerFridrich ({idx=0, t}) {


	return  <Box
		sx={{
			width: '100%', // Remplit toute la largeur
			background: 'linear-gradient(to right, #000848, #0008B8)', // Dégradé de gauche à droite
			color: 'white', // Couleur du texte
			padding: 2, // Padding (ajuste si nécessaire)
			position: 'relative', // Assure que le conteneur est positionné correctement
			left: 0, // Assure que le conteneur commence à gauche
		}}
	>
		<Container>
			<Box display="flex" justifyContent="space-between" alignItems="center">
				<Box>
					<Button variant={idx==0 ? "contained" : "outlined"} href="/methodFridrich" sx={{ m: 1,  }}>{t('title')}</Button>
					<Button variant={idx==1 ? "contained" : "outlined"} href="/methodFridrichOLL" startIcon={<img src={ idx==1 ? "/res/rubik/fridrich/hands.png": "/res/rubik/fridrich/hands.png"} style={{ width: 48, height: 24 }} />}  sx={{ m: 1 }}>OLL</Button>
					<Button variant={idx==2 ? "contained" : "outlined"} href="/methodFridrichPLL"  startIcon={<img src={ idx==2 ? "/res/rubik/fridrich/hands.png": "/res/rubik/fridrich/hands.png"} style={{ width: 48, height: 24 }} />} sx={{ m: 1  }}>PLL</Button>
				</Box>
				<Box>
					<Button variant={idx==3 ? "contained" : "outlined"} href="/methodFridrichOLL_LH" startIcon={<img src={idx==3 ?  "/res/rubik/fridrich/hand_left.png" : "/res/rubik/fridrich/hand_left.png"} style={{ width: 24, height: 24 }} />} sx={{ m: 1  }}>OLL Left Hand only</Button>
					<Button variant={idx==4 ? "contained" : "outlined"} href="/methodFridrichPLL_LH" startIcon={<img src={idx==4 ?  "/res/rubik/fridrich/hand_left.png" : "/res/rubik/fridrich/hand_left.png"} style={{ width: 24, height: 24 }} />} sx={{ m: 1  }}>PLL Left Hand only</Button>
				</Box>
			</Box>
		</Container>

	</Box>
};

export { BannerFridrich };