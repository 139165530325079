import React from "react";
import { useState, useEffect } from 'react'

import Menu from "menu";
import Banner from "banner";

import { Container, Typography, Paper, Box , Button, LinearProgress, Avatar, Link, Stack } from '@mui/material';
import { Grid2 } from '@mui/material';


import './methodLayer.css';
import { useTranslation } from 'react-i18next';

import { Paragraph } from "toolbox";





const moves = [ "U", "R", "F", "L", "D", "B"];

const corners = [["/res/rubik/layer/corner11.png", "Coin bien placé et bien orienté", "lightgreen"],
["/res/rubik/layer/corner12.png", "Coin bien placé mais mal orienté" , "lightgreen"],
["/res/rubik/layer/corner13.png", "Coin bien placé mais mal orienté" , "lightgreen"],
["/res/rubik/layer/corner14.png", "Coin mal placé", "#f1948a"]];



const Header = ({title, id}) => <Paper sx={{ backgroundColor: "#d6eaf8 ", padding: 1, mt:5 }} >
		<h1 id={id} >{title}</h1>
	</Paper>;



export default (() => {
	useEffect(() => {
        // Code JavaScript ici
        const script = document.createElement('script');
        script.src = "https://sebastienlabonne.dev/jquery.min.js";
        script.async = true;
        document.head.appendChild(script);

        const script2 = document.createElement('script');
        script2.src = "https://sebastienlabonne.dev/roofpig_and_three.min.js";
        script2.async = true;
        document.head.appendChild(script2);
    
        // Cleanup si nécessaire (si le script est temporaire par exemple)
        return () => {
			document.head.removeChild(script);
			document.head.removeChild(script2);
        };
      }, []); // Le tableau vide [] signifie que l'effet ne s'exécutera qu'une seule fois après le montage


	  const { t, i18n } = useTranslation("methodLayer");

	return <Box>
		<Menu idx={2}/>
		<Banner title1="Rubik's Cube"  title2={t('title')} image="/res/rubik/layer/rubik_globe.png" page="rubikLayer"/>


		<Container>

		
			<Typography variant="h1_rubik">{t('title')}</Typography>

			<Grid2 container spacing={2}>
				<Grid2 size={11}>

				<Paragraph  text='p1' t={t} />

				<Paragraph  text='p2' t={t} />

				<Paragraph  text='p3' t={t} />

				</Grid2>
					<Grid2 size={1}>
						<Link href="/res/rubik/layer/Rubik_Layer_Quick_Card.pdf"> <img  src="/res/rubik/layer/sheet.png" title="Quick Card" className="responsive-image"/></Link>
					</Grid2>
				</Grid2>



				
		</Container>


		<Container sx={{mt:4, mb:5}}>

			<Grid2 container spacing={2}>
				<Grid2 size={9}>

					<ul>
						<li><b><a href="#layer1">{t('layer1_title')}</a></b></li>
						<ul>
							<li><a href="#cross1">{"1) "+t('layer11_title')}</a></li>
							<li><a href="#corners1">{"2) "+t('layer12_title')}</a></li>
						</ul>

						<li><b><a href="#layer2">{t('layer2_title')}</a></b></li>
						<ul>
							<li><a href="#layer21">{"3) "+t('layer21_title')}</a></li>
							<li><a href="#layer22">{"4) "+t('layer22_title')}</a></li>
						</ul>

						<li><b><a href="#layer3">{t('layer3_title')}</a></b></li>
						<ul>
							<li><a href="#layer31">{"5) "+t('layer31_title')}</a></li>
							<li><a href="#layer32">{"6) "+t('layer32_title')}</a></li>
							<li><a href="#layer33">{"7) "+t('layer33_title')}</a></li>
							<li><a href="#layer34">{"8) "+t('layer34_title')}</a></li>
						</ul>
					</ul>

				</Grid2>
					<Grid2 size={3}>
						<img  src="/res/rubik/layer/layers.png" className="responsive-image"/>
					</Grid2>
				</Grid2>
			
			</Container>
			
		<Container sx={{mt:4, mb:5}}>

		
			<h2>Pré-requis</h2>

			
		
			Avant de commencer, il y a quelques points INDISPENSABLES à bien comprendre :

			<Paper sx={{ backgroundColor: "#fef9e7 ", padding: 2 }} >
				<Grid2 container spacing={2}>
					<Grid2 size={9}>

						Faites bien la distinction entre toutes les pièces qui constituent le cube
					<ul>
						<li>les centres des faces : au nombre de 6, ils sont immobiles et doivent servir de repère pour déterminer la couleur de la face</li>
						<li>les bords : on en compte 12 et ont 2 couleurs</li>
						<li>les coins : il y en a 8 et ont 3 couleurs</li>
					</ul>
					

					</Grid2>
					<Grid2 size={3}>
					<img src="/res/rubik/layer/pieces2.png" />
					</Grid2>
				</Grid2>
				</Paper>

			<Paper sx={{ backgroundColor: "#d6eaf8 ", padding: 2 }} >
				<Paragraph  text='preRequis.p1' t={t}  mt={0}/>
			</Paper>

			
				<Paper sx={{ backgroundColor: "#d1f2eb", padding: 2 }} >
			<h3>Notation des mouvements</h3>
	  			Certaines étapes vont nécessiter l'apprentissage d'algorithmes pour résoudre certaines situations. Les algorithmes seront décrits par une série de lettres:
				<ul>
					<li>U : tourner la face du haut (Up) de 90° dans le sens horaire</li>
					<li>U' : tourner la face du haut de 90° dans le sens anti-horaire</li>
					<li>U2 : tourner la face du haut 2 fois (un demi-tour, 180°)</li>
				</ul>

				Les 6 lettres correspondantes aux 6 mouvements des faces dans le sens horaire correspondent à la premère lettre de chaque face : U(p), R(ight), L(eft), F(ront), D(own) et B(ack) 

				<Grid2 container spacing={1} justifyContent="center" direction="row">
						{moves.map(([k,icon, v]) => { 
							const imageUrl = `/res/rubik/layer/${k}.png`;
							return(
							<Grid2 item xs={2} key={k}>
							<Paper elevation={3}  
								style={{ 
									height: '160px', // Hauteur fixe
									display: 'flex', 
									alignItems: 'center', 
								//	justifyContent: 'center',
									textAlign: 'center' ,
									flexDirection: 'column',
								}}
							>
								<Typography variant="h5">{k}</Typography>
					<img src={icon} width="40px"/>
								<Typography>{v}</Typography>
								<img src={imageUrl} width="100px"/>
							</Paper>
							</Grid2>
						)
					})}
				</Grid2>

				et vous inverserez le mouvement s'il y a une apostrophe après la lettre<br />

				</Paper>


				<div>
					Allez, c'est parti pour la résolution!
				</div>





				<Header title={"1) "+t('layer1_title')} id="layer1"/>
				

				<Box display="flex" alignItems="center" justifyContent="space-between" sx={{mt:3}}>
					<Box ><h2 id="cross1">{"1.1) "+t('layer11_title')}</h2></Box>
					<Box sx={{  width: '10%', marginRight: 2 }}><img  src="/res/rubik/layer/layer11.png" className="responsive-image"/></Box>
				</Box>

				<i>Nous voici donc avec un cube mélangé!...</i>
				<Paragraph  text='cross1' t={t} />

			
				<Stack direction="row" spacing={2}  justifyContent="center">	
					<div className="roofpig" data-config="flags=showalg | colored=U UF R F L D B | colors=U:b R:y B:o L:w | solved=UR UL UB">bord résolu</div>
					<div className="roofpig" data-config="alg=F'| flags=showalg | colored=U UF R F L D B | colors=U:b R:y B:o L:w | solved=UR UL UB"></div>
					<div className="roofpig" data-config="alg=F2| flags=showalg | colored=U UF R F L D B | colors=U:b R:y B:o L:w | solved=UR UL UB"></div>
					</Stack>
			

					<Stack direction="row" spacing={2}  justifyContent="center">	
					<div className="roofpig" data-config="alg=U' R U| flags=showalg | colored=U UF R F L D B | colors=U:b R:y B:o L:w | solved=UR UL UB" ></div>
					<div className="roofpig" data-config="alg=F' U' R U| flags=showalg | colored=U UF R F L D B | colors=U:b R:y B:o L:w | solved=UR UL UB"></div>
					<div className="roofpig" data-config="alg=F U' R U| flags=showalg | colored=U UF R F L D B | colors=U:b R:y B:o L:w | solved=UR UL UB"></div>
				</Stack>







				<Box display="flex" alignItems="center" justifyContent="space-between">
					<Box ><h2 id="corners1">{"1.2) "+t('layer12_title')}</h2></Box>
					<Box sx={{  width: '10%', marginRight: 2, mt: 4 }}><img  src="/res/rubik/layer/layer12.png" className="responsive-image"/></Box>
				</Box>

				<Paragraph  text='face1' t={t} />

				<Stack direction="row" spacing={2}  justifyContent="center">	
					<div className="roofpig" data-config=" flags=showalg | colored=U UF UR UL UB R F L D B URF | colors=U:b R:y B:o L:w ">coin résolu</div>
					<div className="roofpig" data-config="alg=D' R' D R| flags=showalg | colored=U UF UR UL UB R F L D B URF | colors=U:b R:y B:o L:w "></div>
					<div className="roofpig" data-config="alg=D F D' F'| flags=showalg | colored=U UF UR UL UB R F L D B URF | colors=U:b R:y B:o L:w"></div>
				</Stack>

				<Stack direction="row" spacing={2}  justifyContent="center">	
					<div className="roofpig" data-config="alg=D' R' D2 R  D' R' D R | flags=showalg | colored=U UF UR UL UB R F L D B URF | colors=U:b R:y B:o L:w"></div>
					<div className="roofpig" data-config="alg=R' D' R D2 F D' F' | flags=showalg | colored=U UF UR UL UB R F L D B URF | colors=U:b R:y B:o L:w"></div>
					<div className="roofpig" data-config="alg=R' D R D' R' D R | flags=showalg | colored=U UF UR UL UB R F L D B URF | colors=U:b R:y B:o L:w"></div>
				</Stack>

				Répétez cette opération pour 3 des 4 coins de la face supérieure... inutile de résoudre le dernier, cet emplacement libre nous sera utile dans l'étape suivante.
				Et voilà, la première face est (presque) finie!












				<Header title={"2) "+t('layer2_title')} id="layer2"/>
			
				<Box display="flex" alignItems="center" justifyContent="space-between">
					<Box ><h2 id="layer21">{"2.1) "+t('layer21_title')}</h2></Box>
					<Box sx={{  width: '10%', marginRight: 2, mt:4 }}><img  src="/res/rubik/layer/layer21.png" className="responsive-image"/></Box>
				</Box>
				<Paragraph  text='couronne' t={t}  />
				<Stack direction="row" spacing={2}  justifyContent="center">		
					<div className="roofpig" data-config="alg=R' D R| flags=showalg | colored=U UF UR UB UL ULF ULB URB  R F L D B RF | colors=U:b R:y B:o L:w"></div>
					<div className="roofpig" data-config="alg=F D' F'| flags=showalg | colored=U UF UR UB UL ULF ULB URB  R F L D B RF  | colors=U:b R:y B:o L:w"></div>
				</Stack>
				<Paragraph  text='layer2' t={t} />



				<Box display="flex" alignItems="center" justifyContent="space-between">
					<Box ><h2 id="layer22">{"2.2) "+t('layer22_title')}</h2></Box>
					<Box sx={{  width: '10%', marginRight: 2, mt: 4 }}><img  src="/res/rubik/layer/layer22.png" className="responsive-image"/></Box>
				</Box>

				<Paragraph text='layer2_2'  t={t} />
				
				<Stack direction="row" spacing={2}  justifyContent="center">		
					<div class="roofpig" data-config="alg=D' R' D2 B' D2 B R| flags=showalg | colored=D- | colors=U:b R:y B:o L:w">1er cas</div>
					<div class="roofpig" data-config="alg=D F D2 L D2 L' F'| flags=showalg | colored=D- | colors=U:b R:y B:o L:w">2ème cas</div>
				</Stack>

				Les 2 premières couches sont maintenant finies. 













				<Header title={"3) "+t('layer3_title')} id="layer3"/>
				<Box display="flex" alignItems="center" justifyContent="space-between">
					<Box ><h2 id="layer31">{"3.1) "+t('layer31_title')}</h2></Box>
					<Box sx={{  width: '10%', marginRight: 2, mt:4 }}><img  src="/res/rubik/layer/layer31.png" className="responsive-image"/></Box>
				</Box>


				<Paragraph  text='layer3_1' t={t}  />
						<Stack direction="row" spacing={2}  justifyContent="center">		
							<div class="roofpig" data-config="alg=B L U L' U' B'| flags=showalg | colored=U- fU rU lU bU U | colors=U:g R:r B:w L:o F:y D:b">Forme I</div>
							<div class="roofpig" data-config="alg=B U L U' L' B'| flags=showalg  | colored=U- fU rU lU bU U  | colors=U:g R:r B:w L:o F:y D:b" >Forme L</div>
						</Stack>

				




				<Box display="flex" alignItems="center" justifyContent="space-between">
					<Box ><h2 id="layer32">{"3.2) "+t('layer32_title')}</h2></Box>
					<Box sx={{  width: '10%', marginRight: 2, mt:4 }}><img  src="/res/rubik/layer/layer32.png" className="responsive-image"/></Box>
				</Box>

				<Paragraph  text='layer3_2' t={t}  />

					<Box display="flex" alignItems="center" >
						<Box display="flex" alignItems="center" sx={{mr:4}} >
							<img src="/res/rubik/layer/permuteEdges2.png"sx={{width: '60px'}}/>
							<div class="roofpig" data-config="alg=L' U2 L U L' U L| flags=showalg | colored=U- fU rU lU bU U Fu Ru Lu Bu  | colors=U:g R:r B:w L:o F:y D:b"></div>
						</Box>
						<Box display="flex" alignItems="center" sx={{mr:4}} >
							<img src="/res/rubik/layer/permuteEdges1.png" sx={{width: '60px'}}/>
							<div class="roofpig" data-config="alg=R U2 R' U' R U' R'| flags=showalg | colored=U- fU rU lU bU U Fu Ru Lu Bu  | colors=U:g R:r B:w L:o F:y D:b"></div>
						</Box>
					</Box>



	









				<Box display="flex" alignItems="center" justifyContent="space-between">
					<Box ><h2 id="layer33">{"3.3) "+t('layer33_title')}</h2></Box>
					<Box sx={{  width: '10%', marginRight: 2, mt:4 }}><img  src="/res/rubik/layer/layer33.png" className="responsive-image"/></Box>
				</Box>

				<Paragraph  text='layer3_3' t={t}  />
						<Box display="flex" alignItems="center" justifyContent="space-between">

				</Box>

				<Grid2 container spacing={1} justifyContent="center" direction="row">
						{corners.map(([k, v, color]) => { 
							const imageUrl = `/rubik/layer/${k}.png`;
							return(
							<Grid2 item xs={2} key={k}>
							<Paper elevation={3}  
								style={{ 
									height: '160px', // Hauteur fixe
									display: 'flex', 
									alignItems: 'center', 
									justifyContent: 'center',
									textAlign: 'center' ,
									flexDirection: 'column',

								}}
								sx={{ backgroundColor: color, padding: 2 }}
							>
								<Typography>{v}</Typography>
								<img src={k} width="100px"/>
							</Paper>
							</Grid2>
						)
					})}
				</Grid2>


				<Paragraph  text='layer3_4' t={t}  />

						<Box display="flex" alignItems="center" >
							<Box display="flex" alignItems="center" sx={{mr:4}} >
								<img src="/res/rubik/layer/permuteCorners1.png" sx={{width: '60px'}}/>
								<div class="roofpig" data-config="alg=L' U R U' L U R' U'| flags=showalg  | colors=U:g R:r B:w L:o F:y D:b">Coin bien placé en UFR</div>
							</Box>
							<Box display="flex" alignItems="center" sx={{ml:4}} >

								<img src="/res/rubik/layer/permuteCorners2.png" sx={{width: '60px'}}/>
								<div class="roofpig" data-config="alg=R U' L' U R' U' L U | flags=showalg  | colors=U:g R:r B:w L:o F:y D:b">Coin bien placé en UFL</div>
							</Box>
						</Box>
						Si vous en avez 0 ou 2, faites un des deux algorithmes précédent pour vous retrouver avec un seul coin placé.








				<Box display="flex" alignItems="center" justifyContent="space-between">
					<Box ><h2 id="layer34">{"3.4) "+t('layer34_title')}</h2></Box>
					<Box sx={{  width: '10%', marginRight: 2, mt:4 }}><img  src="/res/rubik/layer/layer34.png" className="responsive-image"/></Box>
				</Box>


				Dernière étape... Le cube est quasiment terminé et, mauvaise nouvelle, on va tout casser !<br />
Mais si tout se passe bien, le cube devrait enfin être terminé à la fin de cette étape délicate...<br />

Tournez le cube maintenant pour avoir la face qui reste à résoudre (ici la verte) sur la face L et après cela, ne faites plus de rotation complète du cube jusqu'à la fin!<br />
				<Stack>
					<div class="roofpig" data-config="alg = z'  | flags=showalg | colors=U:g R:r B:w L:o F:y D:b  | solved=UFR UFL ULB URB | tweaks=X:Rub X:Ubr X:Urb ">TOURNEZ LE CUBE!</div>
				</Stack>

				<Paragraph  text='layer3_5' t={t}  />
			
					<Stack direction="row" spacing={2}  justifyContent="center">	
						<div class="roofpig" data-config="flags=showalg | colors=U:r R:b B:w L:g F:y D:o |solved=UFL FLD LDB R* UF DF BU BD | tweaks=X:Bul X:Ubl X:Lub ">le coin est déjà en place</div>
						<div class="roofpig" data-config="alg =  U R U' R' U R U' R'   | flags=showalg | colors=U:r R:b B:w L:g F:y D:o |solved=UFL FLD LDB R* UF DF BU BD | tweaks=X:Bul X:Ubl X:Lub ">le coin doit être tourné dans le sens horaire</div>
						<div class="roofpig" data-config="alg =R U R' U' R U R' U' | flags=showalg | colors=U:r R:b B:w L:g F:y D:o |  solved=UFL FLD LDB R* UF DF BU BD | tweaks=X:Bul X:Ubl X:Lub">le coin doit être tourné dans le sens anti-horaire</div>
						
					</Stack>


					<Paragraph  text='layer3_6' t={t}  />

					Normalement, en répétant cette opération pour les 4 coins, le cube devrait être totalement résolu.
					<div>
						Dans le cas contraire, cliquez <a href="#layer1">ici</a>
					</div>



						<Grid2 container spacing={2}>
							<Grid2 size={8}>
								<Paragraph  text='layer3_exemple' t={t}  mt={4}/>
							</Grid2>
							<Grid2 size={4}>
								<div class="roofpig" data-config="alg=   U R U' R' U R U' R'  L' R U R' U' R U R' U'  L'  U R U' R' U R U' R'  L'  R U R' U' R U R' U' L'| flags=showalg | colors=U:g R:r B:w L:o F:y D:b | tweaks=X:Bul X:Ubl X:Lub  X:Ful X:Ufl X:Luf   X:Fdl X:Dfl X:Ldf   X:Dbl X:Bdl X:Lbd ">Exemple pratique de résolution des 4 coins</div>
							</Grid2>
						</Grid2>



						Félicitations ! Le cube est maintenant terminé \o/

		</Container>
	</Box>
});