export default 
{
    desc: "Un logiciel qui permet de trouver les étapes pour résoudre un Rubik's Cube mélangé mais aussi de proposer différentes méthodes d'apprentissage de résolution.",
    title2:"Résolution et apprentissage du Rubik's Cube",
    desc2: "Rubik Teacher est un programme qui permet, non seulement d'aider à résoudre un Rubik's Cube mélangé grâce à son mode d'édition, mais aussi surtout d'apprendre à le résoudre selon 2 méthodes différentes au travers du mode apprentissage",
    modeSolve: "Mode Résolution",
    modeSolveDesc: "Dessinez la configuration d'un Rubik's Cube mélangé et le programme trouvera la solution pour le résoudre pas à pas",
    modeLearn: "Mode Apprentissage",
    modeLearnDesc: "Apprenez les méthodes de résolution du Rubik's Cube à travers 2 méthodes selon le niveau",
    modeLearnDesc2: `<ul>
                        Méthode Couche par couche : pour les débutants qui permet de résoudre en une centaine de mouvements
                        Méthode Fridrich : méthode experte, qui permet de résoudre le cube en moins de 60 mouvements et très largement utilisée en compétition pour descendre sous la barre des 10 secondes!
                    </ul>`,

    title1: "Présentation du logiciel",


    title11: `Cube initial`,
    area11: `Cette zone sert à coloriser le cube. La palette permet de "peindre" le cube sur la vue éclatée tandis que les différents boutons servent respectivement à:
<ul>
Remplir le cube en mode "résolu"
Vider complètement le cube
Vérifier la validité du cube
</ul>`,

    title12: `Mélange aléatoire`,
    area12: `Choisissez le nombre de mouvements aléatoires avant de mélanger le cube`,

    title13: `Mouvements`,
    area13: `Cliquez sur l'un des 36 mouvements possibles pour animer le cube 3D, le mélanger, et éventuellement, le résoudre!`,

    title14: `Zone d'affichage du cube 3D`,
    area14: `Les différents boutons servent respectivement à:
    <ul>
recentrer le cube à sa position d'origine
Animer le cube on/off
Nettoyer la zone de texte
</ul>`,

title15: `Zone d'affichage de la solution et des différents algorithmes de résolution`,

title16: `Cube Cible`,
area16: `Décrit la configuration visée aux différentes étapes de résolution`,

title17: `Résolution`,
area17: `Les boutons servent à:
<ul>
Comparer les différentes solutions disponibles pour voir combien de mouvements sont nécessaires avec chacune des méthodes
Résoudre le cube!
</ul>`,

title18: `Lecteur d'algorithmes et contrôle de la vitesse du cube 3D`,


    p2 : `	Si vous avez un Rubik's Cube mélangé et que vous voulez trouver la solution pour le résoudre, ce programme peut le résoudre pour vous:
				<ul>
					Editez le cube en vue éclatée (1.d) pour obtenir le même cube à l'écran que le votre.
					Vérifiez la validité du cube (1.c)
					Choisissez la méthode de résolution (Friedrich pour la plus rapide) et affichez la solution (7.b)
					Cela affichera les mouvements à reproduire à chaque étape de a résolution que vous pouvez re/jouer à une vitesse adaptée grâce au lecteur d'algorithmes (8)
				</ul>`,




    p3: `Si vous souhaitez apprendre à résoudre le Rubik's Cube, sachez que le programme affiche la solution ainsi que les différentes étapes de la résolution. 
					Il permet aussi de naviguer parmi les différents algorithmes de résolution:
					Si vous mélangez le cube (3) et affichez la solution (7.a/b), vous verrez apparaître (5) les algorithmes utilisés pour résoudre chaque étape de la solution.
					En cliquant sur une étape de la solution (5), la vue 3D du cube (4) se mettra à jour à l'état initial de l'étape. La vue (1) affichera le cube correspondant en vue éclatée. La vue (6) affiche l'état recherché et atteint en fin d'étape (les cases grises étant considérées comme neutres à cette étape de résolution)
					Pour connaitre tous les algorithmes nécessaires à la résolution de chaque étapes de chacune des deux méthodes, vous pouvez naviguer parmi eux en choisissant l'algorithme dans la section (7)`,


    
};