import {React, useMemo} from "react";

import { Container, Typography, Box, Link, Grid, Button } from '@mui/material';


import { useTranslation } from 'react-i18next';

import './software.css'

const Platform = ({title, icon, mt = 0, style=0}) => {
	return (	
	<Box display="flex" justifyContent='left' alignItems='center' sx= {{ mb:3, mt: mt}}> 
		<img src={icon} width="4%" style={{ marginRight: "10px" }}/>
		<Typography variant={style == 0 ? "platform" : "default" }>{title}</Typography>
	</Box>)
}

const Category = ({title, icon, ml=0}) => {
	return (	
	<Box display="flex" justifyContent='left' alignItems='center' sx= {{ mt:1, ml:ml, mb:1 }}> 
		<img src={icon} width="3%" style={{ marginRight: "10px" }} className="inverted-image" />
		<Typography variant="category" >{title}</Typography>
	</Box>)
}


function BannerSoftwareHeader ({title, subtitle, icon, type=0}) {
	const { t, i18n } = useTranslation("software");

	return  <Box
	sx={{
		width: '100%', // Remplit toute la largeur
		background: 'linear-gradient(to right, #000848, #0008B8)' , // Dégradé de gauche à droite
		color: 'white', // Couleur du texte
		paddingTop: 2, // Padding (ajuste si nécessaire)
		paddingBottom: 2,
		//position: 'relative', // Assure que le conteneur est positionné correctement
		//left: 0, // Assure que le conteneur commence à gauche
	}}>
		<Container>
				<Grid container spacing={2}>
				<Grid item xs={4} md={9}>
					<Typography variant="h1">{title}</Typography>
					<Typography>{subtitle}</Typography>

					<Platform title="Windows" icon="/res/software/windows.png" style={1}/>
					<Category title={type == 0 ? t('organization'): 'Rubik\'s Cube'} icon={type ==0 ? "/res/software/organisation.png" : "/res/software/rubik_bw.png" }/>

				</Grid>
				<Grid item xs={4} md={2}>
					<img  src={icon} className="responsive-image"/>
				</Grid>

			</Grid>
			</Container>
		</Box>
};

export { BannerSoftwareHeader };