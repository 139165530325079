export default 
{
    title: "My software",
    windowsTxt : "My software developped for Windows, with Qt in C++",
    organization: "Organization",
    download: "Download",





    ti89: {
      desc: "Games developed on the TI-89 in Basic",

      maillon: {
        title: "The Weakest Link",
        desc: "Adaptation of the famous TV game on a calculator, playable with up to 9 players!"
      },

      dcedl: {
        title: "Numbers and Letters",
        desc: "Adaptation of a french TV game, playable alone or with 2 players using the cable"
      }
    }
};
  