import React from "react";


import Menu from "menu";

import { Container, Typography, Grid, Box , Button,  } from '@mui/material';


import Form from "../../form"


import { BannerSoftware } from "Software/bannerSoftware";
import { useTranslation } from 'react-i18next';

import {  SoftwareHeader } from "../software";

import { Paragraph } from "toolbox";
import { BannerSoftwareHeader} from '../bannerSoftwareHeader'



export default (() => {

	const { t, i18n } = useTranslation("stackmatTimer");

	return <Box>
		<Menu idx={1}/>

		<BannerSoftware selected="virtualStackmatTimer" backgroundColor={true}/>
		<BannerSoftwareHeader title="Virtual Stackmat Timer" subtitle={t('title2')} icon="/res/stackmatTimer/stackmatTimerIcon.png" type={1} />


		<Container>

			<SoftwareHeader   program="/res/stackmatTimer/Virtual_StackMat_Timer_v1_1_Setup.exe" t={t} type={1}/>

		

			<Grid container spacing={2} sx={{ mt:2 }}>
                <Grid item xs={4} md={5}>
				<Typography variant="h6">Instructions</Typography>
				<Paragraph text='desc3' t={t} />
					
                </Grid>
                <Grid item xs={4} md={7}>
				<img src="/res/stackmatTimer/virtualStackmat.jpg"  className="responsive-image" />
                </Grid>
            </Grid>


			

	


		<Form title='[Virtual Stackmat Timer]'/>

		</Container>
	</Box>
});