import { Container, Box, Typography, List, ListItem, ListItemText } from '@mui/material';

import { useTranslation } from 'react-i18next';

const Paragraph = ({text, page="", mt=2, t=(e) => e}) => {

  //  const { t, i18n } = useTranslation(page);

	const text_ =  t(text);
    const segments = text_.split('\n');
    console.log(segments)

    var li = false;
    var listItems = [];

	return (<Box display="flex"
      flexDirection="column"
      justifyContent="center"
      sx={{mt:mt}}
    >{segments.map(segment => {
		const tagMatch = segment.match(/<([^>]+)>/);
		var tagName = "body";
		
		if (tagMatch) {
			tagName = tagMatch[1]; // Récupérer le nom de la balise


            if (tagName == "ul")
            {
                li = true;
                return "";
            }

            else if (tagName == "/ul")
            {
                //const content = segment.replace(tagMatch[0], '') ;
              //  var items = content.split("<li>")
                li = false;
                return (
                    <List>
                      {listItems.map((item, index) => (
                        <ListItem key={index}>
                          <ListItemText primary={"- " + item} variant="body1"/>
                        </ListItem>
                      ))}
                    </List>
                  );

            }


            

		}

        if (li)
            {
               // const content = segment.replace(tagMatch[0], '') ;
                listItems.push(segment);
                return "";
            }
		const content = tagMatch ? segment.replace(tagMatch[0], '') : segment; // Enlever la balise pour garder le contenu
            console.log(content);
		return <Typography variant={tagName}>{content}</Typography>
		}
	)}
	</Box>)
}

export {Paragraph}