import {React, useEffect} from "react";

import Menu from "menu";


import { Container, Typography, Box, Link, Grid, Button , Stack} from '@mui/material';

import { BannerFridrich } from "./bannerFridrich";



import { useTranslation } from 'react-i18next';

export default (() => {

	useEffect(() => {
        // Code JavaScript ici
        const script = document.createElement('script');
        script.src = "https://sebastienlabonne.dev/jquery.min.js";
        script.async = true;
        document.head.appendChild(script);

        const script2 = document.createElement('script');
        script2.src = "https://sebastienlabonne.dev/roofpig_and_three.min.js";
        script2.async = true;
        document.head.appendChild(script2);
    
        // Cleanup si nécessaire (si le script est temporaire par exemple)
        return () => {
          document.head.removeChild(script);
		  document.head.removeChild(script2);
        };
      }, []); // Le tableau vide [] signifie que l'effet ne s'exécutera qu'une seule fois après le montage
  
	  const { t, i18n } = useTranslation("methodFridrich");

	return <Container>

		<Menu idx={2} halo={false}/>


		<BannerFridrich idx={3} t={t}/>

		
		<Typography variant="h6">Méthode Fridrich - OLL</Typography>
				

  

<Stack direction="row" spacing={2}>		
	<div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig" data-config="alg=R U R' U' R U' R' U2 R U' R' U2 R U R' | flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black"></div>
	<div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 roofpig" data-config="alg=R U R' U' L' U R U' R' L | flags=showalg | colors=F:#999 B:#999 L:#999 R:#999 U:yellow D:#999 c:black"></div>
	
</Stack>






	</Container>;
});