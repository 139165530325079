export default 
{
  title: 'Liens utiles',

  security: "Sécurité",
  utilities: "Utilitaires",
  image: "Image",
  video: "Vidéo",
  dev: "Développement",

  keepass: "Gestionnaire de mots de passe",
  ditto: "Presse-papier avec historique",
  agentRansack: "Outil de recherche de fichiers",
  greenshot: "Outil de capture d'écran",
  wiztree: "Pour analyser l'espace sur le disque dur",
  gimp: "Outil de retouche d'image avancé",
  paint: "Outil de retouche d'image simple",
  davinciresolve:"Montage vidéo",
  notepad: "Editeur de texte",
  qt: "IDE de développement C++",
  fork: "Client Git",
  python: "",
  filezilla: "Client FTP",
  blender: "Modélisation, animation par ordinateur et rendu en 3D",
  visualstudiocode: "IDE de développement",
  cmake: "Construction logicielle multiplateforme"
};