export default 
{
    desc: "A software for timing yourself while solving the Rubik's Cube or others",
    title2 :"Rubik's Cube timer",
    desc2: "Virtual Stackmat allows you to time yourself. The Stackmat timer is used in Rubik's Cube competitions, in particular...",
    desc3: `Specify your username before starting the timer.
To start the timer:
<ul> 
press the Alt key (to the left of the Space bar) and the Ctrl key (to the right of the Space bar): the red light will turn on. 
Hold for 2 seconds until the green light turns on as well: the timer is now started. 
Now, the timer will start as soon as either of the two keys is released. 
</ul> 
To stop the timer, press the Space bar. The software will then automatically sort the times and calculate the average for each player.`
};
  