import { React, useEffect } from "react";


import Menu from "menu";

import Stack from "@mui/material/Stack"

import { BannerFridrich } from "./bannerFridrich";

import './methodFridrich.css';

import { Container, Typography, Box, Link, Grid, Button } from '@mui/material';

import { useTranslation } from 'react-i18next';

export default (() => {
	useEffect(() => {
    // Code JavaScript ici
    const script = document.createElement('script');
    script.src = "https://sebastienlabonne.dev/jquery.min.js";
    script.async = true;
    document.head.appendChild(script);

    const script2 = document.createElement('script');
    script2.src = "https://sebastienlabonne.dev/roofpig_and_three.min.js";
    script2.async = true;
    document.head.appendChild(script2);

    // Cleanup si nécessaire (si le script est temporaire par exemple)
    return () => {
      document.head.removeChild(script);
		  document.head.removeChild(script2);
    };
  }, []); // Le tableau vide [] signifie que l'effet ne s'exécutera qu'une seule fois après le montage


  const { t, i18n } = useTranslation("methodFridrich");

	return <Container>

		<Menu idx="2" halo={false}/>

    <BannerFridrich idx={2} t={t}  />


	<Typography variant="h6">Méthode Fridrich - PLL</Typography>
				






	<div>
        La probabilité de rencontrer chacun des cas est indiqué en haut à gauche de chaque case
</div>

      <div>
				Les coins sont en place
				</div>
		
      <Stack direction="row" spacing={2}>		
        <div class="roofpig" data-config="alg=B2 U L' R B2 L R' U B2| flags=showalg">1/18</div>
        <div class="roofpig" data-config="alg=B2 U' L' R B2 L R' U' B2| flags=showalg">1/18</div>
        <div class="roofpig b" data-config="alg=x' R U' R' U D R' D U' R' U R D2 F| flags=showalg">1/36</div>		
        <div class="roofpig c" data-config="alg=M2 U M2 U2 M2 U M2| flags=showalg">1/72</div>	
       </Stack>


	   <div>
				Les arêtes sont en place
				</div>
      <Stack direction="row" spacing={2}>		
        <div class="roofpig" data-config="alg=x R' U R' D2 R U' R' D2 R2| flags=showalg">1/18</div>
        <div class="roofpig" data-config="alg=x L U' L D2 L' U L D2 L2| flags=showalg">1/18</div>
        <div class="roofpig b" data-config="alg=F R' F' L F R F' L2 x' U' R U L U' R' U| flags=showalg">1/36</div>	
       </Stack>







	   <div>
				2 coins adjacents en place
			</div>

      <Stack direction="row" spacing={2}>	
			<div class="roofpig" data-config="alg=R U R' U' R' F R2 U' R' U' R U R' F'| flags=showalg">1/18</div>	
			<div class="roofpig" data-config="alg=x' R2 U' R x D2 r' D r D2 x' R D R U R' D'| flags=showalg">1/18</div>
			<div class="roofpig" data-config="alg=U' L2 F' L' U' L' U L F L' U2 L U2 L'| flags=showalg">1/18</div>
			<div class="roofpig" data-config="alg=U R2 F R U R U' R' F' R U2 R' U2 R| flags=showalg">1/18</div>			
       </Stack>
       <Stack direction="row" spacing={2}>	
			<div class="roofpig" data-config="alg=L' U R U' L U2 R' U R U2 R'| flags=showalg">1/18</div>
			<div class="roofpig" data-config="alg=R U' L' U R' U2 L U' L' U2 L| flags=showalg">1/18</div>		
       </Stack>




       <div>
				2 coins opposés en place
			</div>
      <Stack direction="row" spacing={2}>	
      <div class="roofpig" data-config="alg=L' U R U' L U L' U R' U' L U2 R U2 R' | flags=showalg" background="yellow">1/18</div>		
      <div class="roofpig" data-config="alg=F R U' R' U' R U R' F' R U R' U' R' F R F'| flags=showalg">1/18</div>	
        <div class="roofpig c" data-config="alg=R U' L d2 L' U L R' U' R U2 r' F l' U'| flags=showalg">1/72</div>	
        <div class="roofpig c" data-config="alg=L' U R' U2 L U' L' R U R' U2 L U' R U| flags=showalg">1/72</div>	

      </Stack>


  
	
	    <div>
				1 coin et une arête adjacente en place
			</div>


          <Stack direction="row" spacing={2}>		
            <div class="roofpig" data-config="alg=L2 u L' U L' U' L u' r2 y' R' D R| flags=showalg">1/18</div>	
            <div class="roofpig" data-config="alg=R2 u' R U' R U R' u l2 y' x2 R U' R'| flags=showalg">1/18</div>	
            <div class="roofpig" data-config="alg=R U R' y' R2 u' R U' R' U R' u R2| flags=showalg">1/18</div>	
            <div class="roofpig" data-config="alg=L' U' L y' R2 u R' U R U' R u' R2 | flags=showalg">1/18</div>	
          </Stack>
	</Container>



});