import React, { useEffect, useState } from 'react';
import Menu from "menu";
import {  useTranslation } from 'react-i18next';
import { Container, Typography, Grid, Paper, Box , LinearProgress, Avatar, Link } from '@mui/material';

import Banner from "banner";

import './CV.css';
import { t } from 'i18next';

import { Paragraph } from 'toolbox';




  const loisirs = [
    { name: "Sports de raquette", icon:"/res/CV/hobbies/tennis.jpg"},
    { name: "Piano", icon:"/res/CV/hobbies/piano.png"},
    { name: "Dev", icon:"/res/CV/hobbies/dev.webp"},
    { name: "Jeux vidéos", icon:"/res/CV/hobbies/videogames.jpg"},
   // { name: "Natation", icon:"/CV/hobbies/swim.webp"},
  ];

  const postes = [
    {
      name:"forsk",
      logo: '/res/CV/entreprise/forsk.jpg', // URL de l'image du logo
      entreprise: 'Forsk',
      location: { city: 'Toulouse (31)', latitude: 48.8566, longitude: 2.3522 },
        jobs: [{
            dates: 'Jan 2024',
            title: 'Développeur C++',
            description: "",
            env: "C++, C#, Python, Oracle, SQL Server, PostgreSQL"
        }]
    },
    {
      name:"csgroup",
      logo: '/res/CV/entreprise/csgroup.png',
      entreprise: 'CS Group',
      location: { city: 'Toulouse (31)', latitude: 45.7640, longitude: 4.8357 },
      jobs: [{
        dates: 'Jui 2022',
        title: 'Développeur C++/Qt, React',
        description: "Développement C/C++ d'une librairie de protection hors-ligne par licence de logiciel Windows/Linux pour Airbus\nDéveloppement d'un portail Web de gestion / génération de licences\nIntégration de la librairie dans les produits Airbus\nDéveloppement Python d'outils d'automatisation de tâches\nSensibilisation aux problèmes de sécurité, cryptographie",
        env: "C++, Qt, React, Git"
      },
      {
        dates: 'Sep 2021',
        title: 'Développeur C++ / Android / Unreal Engine',
        description: "Développement d'une application de téléassistance en réalité augmentée. L'application consiste à pouvoir faire communiquer  (voix, vidéo, messagerie) un Expert et un Opérateur tout en fournissant des outils d'annotations 3D pour positionner des marqueurs (flèches, tracés de lignes...) dans l'environnement virtuel 3D de l'opérateur\nDéveloppement C++ de l’application PC Windows Expert\nDéveloppement Android/QML de l’application pour l'opérateur avec activation de Android ARCore pour reconstruire l'environnement 3D en utilisant la carte de profondeur\nPortage de la solution Android sous Unreal Engine 4 pour les casques HoloLens2 en reconstruisant l'environnement 3D grâce au spatial mapping du casque.",
        env: "C++, Android, QML, Unreal Engine, Git"
      },
    
    ]
    },
    {
        name:"evs",
        logo: '/res/CV/entreprise/evs.jpg',
        entreprise: 'EVS',
        location: { city: 'Toulouse (31)', latitude: 45.7640, longitude: 4.8357 },
        jobs: [{
            dates: 'Sep 2017',
            title: 'Développeur C++',
            env: "C++, Pyton, Git, Confluence, Jenkins",
            description: "EVS est une société spécialisée dans la conception de serveurs vidéo destinés aux professionnels de l’image, notamment des outils gérant les ralentis pour les grands évènements sportifs\nDéveloppement du logiciel en fin de vie permettant l’encodage et l’extraction des flux vidéo dans un conteneur MXF, utilisé en milieu professionnel"
        }]
    },
      {
        name:"ausy",
        logo: '/res/CV/entreprise/airbus.webp',
        entreprise: 'Ausy - Airbus',
        location: { city: 'Toulouse (31)', latitude: 45.7640, longitude: 4.8357 },
        jobs: [{
            dates: 'Jui 2016',
            title: 'Développeur Java',
            env: " Java, Debian, Apache Ant, Tortoise SVN, JIRA, Jenkins, Linux, Eclipse",
            description: "Développement Java d’une solution virtualisée de communication WAP entre les tablettes embarquées à travers le déploiement sur machine virtuelle Debian d’un point d’accès Wifi dans le cockpit de l’avion\nEnvironnement technique : Java, Debian, Apache Ant, Tortoise SVN, JIRA, Jenkins, Linux, Eclipse"
        }]
        },
      {
        name:"amadeus",
        logo: '/res/CV/entreprise/amadeus.png',
        entreprise: 'Amadeus',
        location: { city: 'Sophia-Antipolis (06)', latitude: 45.7640, longitude: 4.8357 },

        jobs: [{
            dates: 'Mai 2013 ',
            title: 'Développeur C++ / UNIX / Oracle',
            env: "C++, UNIX, Python, Bases De Données Oracle/SQLite, Docker, Couchbase, Edifact, Eclipse, gestionnaire de versions Mercurial, Confluence, suivi JIRA, intégration continue Jenkins, Agile",
            description: `Au sein d’une équipe internationale, je suis en charge de la réplication des données de référence communes aux applications de l’entreprise
Développement C++ sous UNIX d’une architecture distribuée serveur/clients de publication, modification, distribution de fichiers, communiquant par messages Edifact et soumis à de fortes contraintes d’accès non concurrentiels dans une base de données Oracle
Développement Python d’outils d’extraction de logs et d’automatisation des tâches
Extension du framework à un réseau évolutif cascadé de daemons de distribution de fichiers, soulageant le nœud Fail-Over
Migration du framework à une solution de containers Docker déployés dans Amazon Web Services, communiquant avec une base de données NoSQL Couchbase
Intégration continue Jenkins, tests unitaires, codes reviews, mise en production
Travail en méthodologie Agile/Scrum avec des sprints de 3-4 semaines
`
        },


        {
          dates: 'Mai 2012',
          title: 'Développeur Web Java EE',
          env: "Java EE, HTML, CSS, JavaScript, aria-templates, Maven, WebServices SOAP, Microsoft SQL Server, SSL, gestionnaire de versions Clearcase, Jenkins",
          description: `Développement du module de connexion client des sites Web délivrés par Amadeus pour des services de réservation de vols ou d’hôtels, ainsi que du module de connexion interne d’Amadeus avec gestion des droits et privilèges, soumis à de fortes contraintes de sécurité et d’optimisation`
      },
      
      
      {
        dates: 'Nov 2008',
        title: 'Développeur C++ / UNIX / Oracle',
        env: "C++, UNIX, Python, Bases De Données Oracle/SQLite",
        description: `Développeur au sein de l’équipe Ticketing d’Amadeus fournissant une solution d’émission, impression des billets d’avion, qu’ils soient virtuels ou physiques, à partir des données du PNR du passager.
TPF deco : migration de la plateforme Legacy à une solution C++ sous UNIX
Mise en place d’une Guaranteed Delivery : daemon garantissant l’annulation d’un ticket émis corrompu
Fortes contraintes d’optimisation du temps de réponse dans un environnement soumis à un fort trafic de requêtes (~ 20 transactions / sec)
`
    },
      
      ]
      },
  ];



const Banner2 = () => {

    return (
      <Container  sx={{
        width: '100%',
        maxWidth: 'none',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: 'linear-gradient(to right, #000848, #000848)', // Dégradé
        color: 'white',
      }}>
        <Grid container spacing={2}>
           {/* COLONNE 1*/}
          <Grid item xs={12} md={8}>
          <Typography>2005-2008 : Diplôme Ingénieur</Typography>
          <Typography>Centrale Nantes (44)</Typography>
          </Grid>
  
  

          {/* COLONNE 2*/}
          <Grid item xs={12} md={4}>
            <img src="/res/about/profile.jpg"  className="responsive-image"/>
          </Grid>


        </Grid>
      </Container>
    );
            

}




const Skills = ({t}) => {

  const competences = [
    { name: "C++", level: 80 , icon:"/res/about/c++.png", color: "blue"},
    { name: "Qt", level: 90 , icon:"/res/links/qt.png" , color: "blue"},
    { name: "Python", level: 75 , icon:"/res/links/python.png" , color: "blue"},
    { name: "React", level: 70 , icon:"/res/about/react.png" , color: "navy"},
    { name: "Git", level: 70 , icon:"/res/links/git.webp", color: "green"},
    { name: t('db'), level: 65 , icon:"/res/CV/database.jpeg", color: "orange"},
  ];

    const [progressValues, setProgressValues] = useState(Array(competences.length).fill(0));




  
    useEffect(() => {
      const duration = 100; // Durée de l'animation en ms
      const stepTime = 20; // Intervalle de temps entre chaque étape
      const steps = duration / stepTime; // Nombre total d'étapes
  
      const interval = setInterval(() => {
        setProgressValues((prevValues) => {
          return prevValues.map((value, index) => {
            if (value < competences[index].level) {
              return Math.min(value + (competences[index].level / steps), competences[index].level);
            }
            return value;
          });
        });
      }, stepTime);
  
      // Stoppe l'intervalle si toutes les barres atteignent leur valeur
      const allValuesFilled = progressValues.every((value, index) => value >= competences[index].level);
      if (allValuesFilled) {
        clearInterval(interval);
      }
  
      return () => clearInterval(interval);
    }, [progressValues]);
  
    return (
    <Paper style={{ padding: '20px', margin:'10px' }}>
    <Typography variant="h6" component="h3" gutterBottom>{t('skills')} </Typography>
  {competences.map((competence, index, icon) => (
    <Box key={index} mb={2}>
       
        <Box display="flex" justifyContent="space-between" alignItems="center">
        
        <Typography variant="subtitle1"><Box sx={{ fontWeight: 'bold' }}>{competence.name}</Box></Typography>
        <img src={competence.icon} width="40px"/>
    </Box>
      <LinearProgress variant="determinate" value={progressValues[index]} sx={{  '& .MuiLinearProgress-bar1Determinate': {  backgroundColor: competence.color } }}/>
    </Box>
    ))}
    </Paper>
    )
};


  
const Loisirs2 = () => {
    return (
        <Paper style={{ padding: '20px' , margin:'10px' }}>
            <Typography variant="h6" component="h3" gutterBottom>Loisirs</Typography>
            <Box display="flex" justifyContent="space-between" alignItems="center">
                {loisirs.map((loisir) => (<img src={loisir.icon} width="60px" title={loisir.name} />))}
            </Box>
        </Paper>
    )
};

  
const Languages = ({t}) => {
    const langages = [
        { name: t('lang.french'), level: 100, img: '/res/lang/fr.png' },
        { name: t('lang.english'), level: 75, img: '/res/lang/en.png' },
      ];
  
    const [progressValues, setProgressValues] = useState(Array(langages.length).fill(0));
  
    useEffect(() => {
      const duration = 100; // Durée de l'animation en ms
      const stepTime = 20; // Intervalle de temps entre chaque étape
      const steps = duration / stepTime; // Nombre total d'étapes
  
      const interval = setInterval(() => {
        setProgressValues((prevValues) => {
          return prevValues.map((value, index) => {
            if (value < langages[index].level) {
              return Math.min(value + (langages[index].level / steps), langages[index].level);
            }
            return value;
          });
        });
      }, stepTime);
  
      // Stoppe l'intervalle si toutes les barres atteignent leur valeur
      const allValuesFilled = progressValues.every((value, index) => value >= langages[index].level);
      if (allValuesFilled) {
        clearInterval(interval);
      }
  
      return () => clearInterval(interval);
    }, [progressValues]);
  
    return (
    <Paper style={{ padding: '20px', margin:'10px' }}>
    <Typography variant="h6" component="h3" gutterBottom>
    {t('languages')}
  </Typography>
  {langages.map((lang, index, icon) => (
    <Box key={index} mb={2}>
       
        <Box display="flex" justifyContent="space-between" alignItems="center">
        
        <Typography variant="subtitle1"><Box sx={{ fontWeight: 'bold' }}>{lang.name}</Box></Typography>
        <img src={lang.img} width="40px"/>
    </Box>



      <LinearProgress variant="determinate" value={progressValues[index]}  sx={{  '& .MuiLinearProgress-bar1Determinate': {  backgroundColor: 'purple' } }}/>
    </Box>
    ))}
    </Paper>
    )
};
  
  
  
const Jobs = ({t}) => {
    return (
        <>
    <Typography variant="h6" component="h3" gutterBottom>{t('experience')}</Typography>
        {postes.map((poste, index) => (
          <Paper key={index} style={{ padding: '10px', marginBottom: '10px' }}>
            <Grid container spacing={2} alignItems="center" >
              <Grid item>
                <Avatar src={poste.logo} alt={poste.entreprise}  sx={{ width: 100, height: 100 }}/>
              </Grid>
              <Grid item xs>
               
                <Box display="flex" alignItems="center" mt={1} justifyContent="space-between">
                  <Typography variant="h6">{poste.entreprise}</Typography>
                  {/*<MapIcon color="action" />*/}
                  <Typography variant="body2" color="textSecondary">{poste.location.city}</Typography>
                </Box>


                {poste.jobs.map((post, indexPost) => {
                   const missionKey = `jobs.${poste.name}.missions.${indexPost}.poste`;

                   const missionDesc = `jobs.${poste.name}.missions.${indexPost}.desc`;

                   const missionEnv = `jobs.${poste.name}.missions.${indexPost}.env`;

                  
                  return <Box sx={{ m: 3 }}>
                  <Box display="flex" alignItems="center" mt={1} justifyContent="space-between">
                    <Typography variant="subtitle2" ><Box sx={{ fontWeight: 'bold' }}>{t(missionKey)}</Box></Typography>
                    <Typography variant="body2" color="textSecondary">{post.dates}</Typography>
                  </Box>
                  <Paragraph text={missionDesc} t={t} />
                  
                  {/*post.description.split('\n').map(l => <Typography variant="body2">{l}</Typography>)*/}
                  <Typography variant="body2" color="textSecondary"><Box sx={{ fontStyle: 'italic' }}>{t('techEnv')}: {t(missionEnv)}</Box></Typography>
                  
                  </Box>
                }
                )
        }
              </Grid>
            </Grid>
          </Paper>
        ))}
</>)
};







const Formation = ({t}) => {
  return    (     
  <Paper style={{ padding: '20px' , margin:'10px'}}>
    <Typography variant="h6" component="h3" gutterBottom>{t('formation.title')}</Typography>

    <Grid container spacing={2}>
      <Grid item xs={12} md={4}>
      2005-2008
      </Grid>
      <Grid item xs={12} md={8}>
      <Typography>{t('formation.engineer')}</Typography>
      <Typography>Centrale Nantes (44)</Typography>
      </Grid>
    </Grid>

    <Grid container spacing={2}>
      <Grid item xs={12} md={4}>
        2002
      </Grid>
      <Grid item xs={12} md={8}>
      <Typography>{t('formation.bac')}</Typography>
      </Grid>
    </Grid>
</Paper>);
}




const Contact = ({t}) => {
  return    (     
    <Paper style={{ padding: '20px' , margin:'10px'}}>
      <Typography variant="h5" component="h2">{t('contact')}</Typography>
      <Box display="flex" alignItems="center" mt={1}>
        <img src="/res/about/email.png" width="40px"/>
        <Link href="mailto:sebastien.labonne@gmail.com">sebastien.labonne@gmail.com</Link>
      </Box>
      <Box display="flex" alignItems="center" mt={1}>
        <img src="/res/about/linkedin.png" width="40px"/>
        <Typography><Link href="https://www.linkedin.com/in/slabonne/" >LinkedIn</Link></Typography>
      </Box>
  </Paper>
);
}





  





const CV = ({t}) => {
  return (
    <Container maxWidth="lg">
        <Container maxWidth="xl">
      <Grid container spacing={2}>
        <Grid item xs={12} md={8}>
         
           <Jobs t={t}/>
       
        </Grid>





        {/* COLONNE 2 */}
        <Grid item xs={12} md={4}>
            <Skills t={t}/>
            <Languages t={t}/>
            <Formation t={t}/>
            <Contact t={t}/>
           {/* <Loisirs2 /> */}
        </Grid>
      </Grid>
    </Container>
    </Container>
  );
};




export default (() => {
	const { t, i18n } = useTranslation("CV");

	return <Box>
		<Menu idx={4}/>
		<Banner title1={t('title')} title2="Sébastien LABONNE" title3="Toulouse (31) - France" image="/res/about/profile.jpg" page="about"/>

    <CV t={t}/>

	</Box>
});