import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  body: {
    fontFamily: 'Verdana, "Roboto", "Helvetica", "Arial", sans-serif',
  },
    container: {
        div:{   
            p: {
                fontFamily: 'Verdana, "Roboto", "Helvetica", "Arial", sans-serif',
            }
        },
        body1: {
            fontSize: '1rem',
            color: 'blue',
          },
        
    },
    p: {
        fontFamily: 'Verdana, "Roboto", "Helvetica", "Arial", sans-serif',
    },

  typography: {
    fontFamily: 'Verdana, "Roboto", "Helvetica", "Arial", sans-serif',
    h1: {
      fontSize: '2rem',
      fontWeight: 700,
      color: '#eee',
    },
    h2: {
      fontSize: '1.5rem',
      fontWeight: 400,
      color: '#ddd',
    },
    h3: {
      fontSize: '1.rem',
      fontWeight: 200,
      color: '#ccc',
    },

    h2_home: {
      fontSize: '2rem',
      fontWeight: 700,
      color: 'black',
       display: 'block', 
       margin: '20px 0' 
    },


    h1_software: {
      fontSize: '2rem',
      fontWeight: 700,
      color: 'black',
       display: 'block', 
       margin: '20px 0' 
    },
    h2_software: {
      fontSize: '1.5rem',
      fontWeight: 400,
      color: 'black',
      display: 'block', 
      margin: '20px 0' 
    },
    software_platform:{
      fontSize: '1.0rem',
      fontWeight: 200,
      color: 'black',
      display: 'block', 
      margin: '20px 0' 
    },

    h1_rubik: {
      fontSize: '2rem',
      fontWeight: 700,
      color: 'black',
       display: 'block', 
       margin: '20px 0' 
    },




    body1: {
      fontSize: '1rem',
     // color: 'green',
    },
    platform: {
      fontSize: '1.5rem',
      fontWeight: 600,
      color: "blue"
    }
    // Ajoute d'autres styles ici
  },
});

export default theme;
