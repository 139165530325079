import React from "react";

import { Container, Typography, Box, Link, Grid, Button } from '@mui/material';

import Menu from "menu"
import Banner from 'banner'

export default (() => {
	return <Box>
		<Menu />

		<Banner title1="Rien par ici"/>

	</Box>
});