import {React, useMemo} from "react";

import { Container, Typography, Box,Link } from '@mui/material';

import { useTranslation, initReactI18next  } from 'react-i18next';
import i18n from 'i18next';


import './menu.css'

import { createTheme, ThemeProvider } from '@mui/material/styles';






function Menu ({idx=0, halo=true}) {

	
	const { t, i18n } = useTranslation("home");


	const changeLanguage = (lng) => {
		i18n.changeLanguage(lng); 
		localStorage.setItem('language', lng);
	};


	var lng = i18n.language



	const Language = ({lang, lng}) => 
	<img
		src={"/res/lang/"+lang+".png"}
		width={lng == lang ? "50px" : "40px"}
		onClick={() => changeLanguage(lang)}
		className={lng == lang ? "" : "attenuer-image"}
		
		style={{ marginLeft: '6px', 
			borderRadius:'10px' }}

			

	/>;



	const MyLink = ({title, href="/", activated=false}) => {
		return <Link href={href} underline="none" m={2} sx={{ 
			fontFamily:'Verdana', 
			color: activated ? 'white' : '#bbb', 
			fontWeight: activated ? 'bold' : 'normal' 
		}}>
			{title}
		</Link>
	}
	


	return <Box
		sx={{
			width: '100%', // Remplit toute la largeur
			background: 'linear-gradient(to right, #000848,  #0008B8)', // Dégradé de gauche à droite
			color: 'white', // Couleur du texte
			padding: 2, // Padding (ajuste si nécessaire)
			position: 'relative', // Assure que le conteneur est positionné correctement
			left: 0, // Assure que le conteneur commence à gauche
		}}
	>
		<Container  className="menu">
			<Box display="flex" alignItems="center" mt={1} justifyContent="space-between">
				<Link href="/" underline="none">
					<img src="/logo.png" width="60px"/>
				</Link>

				<Box display="flex" sx={{ justifyContent: 'flex-end',  flexGrow: 1 }}>
					<MyLink title={t('menu.home')} activated={idx==0}/>
					<MyLink title={t('menu.software')} href="/software" activated={idx==1}/>
					<MyLink title={t('menu.rubik')} href="/rubik" activated={idx==2}/>
					<MyLink title={t('menu.links')} href="/links" activated={idx==3}/>
					<MyLink title={t('menu.about')} href="/about" activated={idx==4}/>
				</Box>

				<Box display="flex" alignItems="center" justifyContent="space-between"  >
					<Language lang={"fr"}  lng={lng} />
					<Language lang={"en"}  lng={lng} />
				</Box>
			</Box>
		</Container>
  </Box>

};

export default Menu;