import React from "react";

import Menu from "menu";
import Banner from "banner";

import { Container, Typography, Grid, Paper, Box , Button, LinearProgress, Avatar, Link } from '@mui/material';

import { useTranslation } from 'react-i18next';

import {Paragraph } from "toolbox"


import { BannerSoftware } from "Software/bannerSoftware";


const BannerRubik = () => {

	const t1 = useTranslation("methodLayer");
	const tFridrich = useTranslation("methodFridrich");

	return (<Box display="flex">
		<Button variant="contained" href="/methodLayer" sx={{ m: 1 }}>{t1.t('title')}</Button>
		<Button variant="contained" href="/methodFridrich" sx={{ m: 1 }}>{tFridrich.t('title')}</Button>
	</Box>)
}






export default (() => {

	const { t, i18n } = useTranslation("home");

	return <Box>
		<Menu idx={0}/>
		<Banner title1={t('title')} title2="Sébastien LABONNE" image="/home.png" page="home" youtube={true}/>

		

		<Container>
			<Paragraph  text='desc' t={t} />

			<Paper sx={{ m: 3 }}>
				<Grid container spacing={2}>
					<Grid item xs={4} md={2}>
						<img  src="/res/software/software.png" className="responsive-image"/>
					</Grid>
					<Grid item xs={4} md={9}>
						<Typography variant="h2_home">{t('software')}</Typography>
						<BannerSoftware style={1}/>
					</Grid>
				</Grid>
			</Paper>


			<Paper sx={{ m: 3 }}>
				<Grid container spacing={2}>
					<Grid item xs={4} md={2}>
						<img  src="/res/rubik/rubik.png" className="responsive-image"/>
					</Grid>
					<Grid item xs={4} md={9}>
						<Typography variant="h2_home">{t('rubik')}</Typography>
						<BannerRubik t={t}/>
					</Grid>
				</Grid>
			</Paper>

		</Container>
	</Box>
});