export default 
{
  title: 'Useful links',

  security: "Security",
  utilities: "Utilities",
  image: "Image",
  video: "Video",
  dev: "Development",
  
  keepass: "Password manager",
  ditto: "Clipboard with history",
  agentRansack: "Tool to search for files",
  greenshot: "Screenshots tool",
  wiztree: "To analyze the space on the hard drive",
  gimp: "Advanced image editing tool",
  paint: "Simple image editing tool",
  davinciresolve:"Video editing",
  notepad: "Text editor",
  qt: "C++ development IDE",
  fork: "Git client",
  python: "",
  filezilla: "FTP client",
  blender: "Modeling, computer animation, and 3D rendering",
  visualstudiocode: "Development IDE",
  cmake: "Cross-platform software development"
};
  